import { React, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { localSignIn } from "../../lib/signin";
import { togglePassword } from "../../lib/common";
import config from "../../config";
import Footer from "../layout/Footer";
import PubBar from "../widgets/public/Bar";

const Login = ({ language, checkPolicies }) => {
  ////////////// INITIALIZE //////////////
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const def_focus = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
    setUsername("");
    def_focus.current.focus();
  }, []);

  ////////////// LOGIN //////////////
  const loginError = (message) => {
    if (!username || username.trim() === "") {
      setError("");
      document.getElementById("username_field").focus();
    } else if (!password) {
      setError("");
      document.getElementById("password_field").focus();
    } else {
      setError(message);
    }
  };

  const submitLogin = async (e) => {
    if (e) e.preventDefault();
    localStorage.clear();

    if (username.trim() !== "" && password.trim()) {
      const configurationObject = {
        url: `${config.server.api + "/auth/signin/hub"}`,
        method: "POST",
        data: { username: username.trim(), password: password },
        withCredentials: true,
        credentials: "include",
      };
      document.getElementById("loader").style.display = "inline";
      axios(configurationObject)
        .then((response) => {
          document.getElementById("loader").style.display = "none";
          if (response.status === 200) {
            localSignIn(dispatch, response.data);
          } else if (response.data.error === "INACTIVE") {
            loginError(language.labels.error.account_inactive);
          } else {
            loginError(language.labels.error.signin_credentials);
          }
        })
        .catch((error) => {
          document.getElementById("loader").style.display = "none";
          console.error(`LOGGED ERROR: \n`, error);
          loginError(language.labels.error.unknown);
        });
    } else {
      loginError(language.labels.error.signin_credentials);
    }
  };

  ////////////// RENDER GUI //////////////

  return (
    <>
      <PubBar language={language} />
      <main className="login">
        <h1
          className="font-yongo"
          style={{
            textAlign: "center",
            fontSize: "3em",
            marginBottom: "0.5em",
          }}
        >
          {language.labels.authentication.signin.yongo}
        </h1>

        <form
          className="login-form"
          aria-label={language.labels.aria.form.signin}
          onSubmit={(e) => submitLogin(e)}
          autoComplete="off"
        >
          {/* enter id */}
          <input
            ref={def_focus}
            id="username_field"
            style={{ marginTop: "1rem", width: "100%" }}
            className="login-input"
            placeholder={language.labels.authentication.username_enter}
            aria-label={language.labels.authentication.username_enter}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <div
            className="password"
            style={{ marginTop: "1.5rem", width: "100%" }}
          >
            <input
              id="password_field"
              className="login-input"
              placeholder={language.labels.authentication.password_enter}
              aria-label={language.labels.authentication.password_enter}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              autoComplete="one-time-code"
            />
            <div
              role="button"
              aria-label={language.labels.aria.password_show_hide}
              onKeyUpCapture={(e) =>
                e.key === "Enter" &&
                togglePassword(e.target, showPassword, setShowPassword)
              }
              tabIndex={0}
              onClick={(e) =>
                togglePassword(e.target, showPassword, setShowPassword)
              }
            >
              -
            </div>
          </div>

          {/* recovery links */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "0.5em",
              fontSize: "0.8em",
              gap: "0.5em",
            }}
          >
            <div
              style={{ fontWeight: 300 }}
              className="link"
              onClick={() =>
                navigate("/forgot", { state: { page: "password" } })
              }
              onKeyUpCapture={(e) =>
                e.key === "Enter" &&
                navigate("/forgot", { state: { page: "password" } })
              }
              tabIndex="0"
              role="link"
            >
              {language.labels.authentication.password_forgot}
            </div>
            <div className="font-soft">|</div>
            <div
              style={{ fontWeight: 300 }}
              className="link"
              onClick={() =>
                navigate("/forgot", { state: { page: "username" } })
              }
              onKeyUpCapture={(e) =>
                e.key === "Enter" &&
                navigate("/forgot", { state: { page: "username" } })
              }
              tabIndex="0"
              role="link"
            >
              {language.labels.authentication.username_forgot}
            </div>
          </div>

          {error && (
            <div
              style={{ fontWeight: 600, marginTop: "1.5rem" }}
              className="errtext"
              role="alert"
            >
              {error}
            </div>
          )}

          <button
            className="button"
            type="submit"
            style={{ width: "100%", marginTop: "2.5rem", fontSize: "1.1rem" }}
          >
            {language.labels.authentication.signin.user}
          </button>
        </form>

        <div className="join-container">
          <h2 className="heading2">
            {language.labels.authentication.no_account}
          </h2>
          <div
            role="link"
            className="link"
            style={{ padding: "0 0.25em" }}
            onClick={() => navigate(`/signup`)}
            onKeyUpCapture={(e) => e.key === "Enter" && navigate("/signup")}
            tabIndex={0}
          >
            {language.labels.authentication.signup.join}
          </div>
        </div>
      </main>
      <Footer
        language={language}
        checkPolicies={checkPolicies}
        ref={def_focus}
      />
    </>
  );
};

export default Login;
