import { React, useState, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector, useDispatch } from "react-redux";

import { setCommunities } from "../../../../features/communities";

import config from "../../../../config";
import { ImageEditor } from "../../../widgets";
import axiosCall from "../../../../lib/axios";
import { sortArrObj } from "../../../../lib/common";

const Details = ({ language, signout, animation, next, navigate, states }) => {
  ////////////// INITIALIZATION //////////////////
  const communities = useSelector((state) => state.communities.value);
  const dispatch = useDispatch();

  //image editor ref
  const canvas_ref = useRef();
  //submit button ref
  const submit_button = useRef();

  //error and alert state
  const [errors, setErrors] = useState({});
  const [resetAlert, setResetAlert] = useState(null);

  //image
  const [file, setFile] = useState(null);

  useEffect(() => {
    // def_focus.current.focus()
    if (animation && animation.step === 1) {
      if (animation.direction === 1) {
        document.getElementById("detailsForm").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("detailsForm").style.animationName = "slideout";
        document.getElementById("detailsForm").style.animationDirection =
          "reverse";
      }
    }
  }, []);

  ////////////// ADD NEW COMMUNITY //////////////////

  //API call to add new community
  const addNew = async (data) => {
    if (data.error) {
      try {
        let err_cpy = {};
        err_cpy = { submit: language.labels.error[data.error] };
        setErrors((errors) => ({
          ...errors,
          ...err_cpy,
        }));
      } catch (err) {
        let err_cpy = {};
        err_cpy = { submit: language.labels.error.unknown };
        setErrors((errors) => ({
          ...errors,
          ...err_cpy,
        }));
      }
    } else {
      const community = {
        id: data._id,
        role: "role_owner",
        uname: data.uname,
        crest: data.crest,
        title: data.title,
        description: data.description,
        type: data.type,
      };

      let _communities = [...communities];
      _communities.push(community);
      _communities.sort(sortArrObj("uname", language.lang));
      dispatch(setCommunities(_communities));
      localStorage.setItem("communities", JSON.stringify(_communities));

      navigate("/communityhub");
    }
  };

  //validate new community submission form
  const submitForm = async (e) => {
    e.preventDefault();

    let err_cpy = { ...errors };
    delete err_cpy["closed"];
    delete err_cpy["name"];
    delete err_cpy["description"];
    delete err_cpy["image"];
    delete err_cpy["submit"];
    setErrors((errors) => ({
      ...err_cpy,
    }));
    setErrors(err_cpy);
    setResetAlert(null);

    var data = { uname: states.uname.toLowerCase().trim() };

    //add title
    if (states.title.trim() !== "") {
      data.title = states.title.trim();
    } else {
      let err_cpy = {};
      err_cpy = { name: language.labels.app.no_name };
      setErrors((errors) => ({
        ...errors,
        ...err_cpy,
      }));
      document.getElementById("name_field").focus();
      return;
    }

    //add description
    if (states.description.trim() !== "") {
      data.description = states.description.trim();
    } else {
      let err_cpy = {};
      err_cpy = { description: language.labels.app.no_description };
      setErrors((errors) => ({
        ...errors,
        ...err_cpy,
      }));
      document.getElementById("desc_field").focus();
      return;
    }

    //add image
    let canvas = canvas_ref.current;
    if (canvas && file) {
      var data_uri = canvas.toDataURL();
      data.crest = data_uri;
    } else {
      let err_cpy = {};
      err_cpy = { image: language.labels.app.no_profile_image };
      setErrors((errors) => ({
        ...errors,
        ...err_cpy,
      }));
      canvas_ref.current.focus();
      return;
    }

    // try and submit data
    let result = await axiosCall("community/create", data);
    if (result.success) {
      //SUCCESS
      addNew(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      submitForm(e);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const resetForm = () => {
    setErrors({});
    states.setTitle("");
    states.setDescription("");
    setFile(null);
    setResetAlert(language.labels.aria.form.reset);
    document.getElementById("name_field").focus();
  };

  //////////// USER INTERACTIONS ///////////
  const startOver = () => {
    document.getElementById("detailsForm").style.animationName = "slidein";
    document.getElementById("detailsForm").style.animationDirection = "reverse";
    next({ step: 0, direction: -1 });
  };

  ////////////// RENDER GUI //////////////////
  return (
    <div style={{ position: "relative", maxWidth: "44rem" }}>
      <form
        id="detailsForm"
        aria-label={language.labels.aria.form.new_community}
        className="block-step page-section"
        onSubmit={(e) => submitForm(e)}
        onReset={(e) => resetForm(e)}
      >
        {/* uname */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>{language.labels.communities.uname}</div>
          <div style={{ minWidth: "1rem" }}></div>
          <div className="quote-text">{states.uname.toLowerCase()}</div>
        </div>
        <div>
          <span
            role="link"
            className="link"
            style={{ fontSize: "0.8em", marginTop: "0.5em" }}
            onClick={() => startOver()}
            onKeyUpCapture={(e) => e.key === "Enter" && startOver()}
            tabIndex={0}
          >
            {language.labels.app.change.toLowerCase()}
          </span>
        </div>

        <h2
          className="heading2"
          style={{
            fontSize: "1.1em",
            fontWeight: "500",
            margin: "1em 0 0.5em",
          }}
        >
          {language.labels.communities.details}
        </h2>

        {/* community name */}
        <label
          htmlFor="name_field"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          {language.labels.communities.enter_name}
        </label>
        {errors.name && (
          <div className="errtext" role="alert">
            {errors.name}
          </div>
        )}
        <input
          id="name_field"
          value={states.title}
          onChange={(e) => {
            states.setTitle(e.target.value);
          }}
          placeholder={language.labels.communities.community_name}
          aria-placeholder={language.labels.communities.community_name}
          style={{ width: "100%", maxWidth: "44rem", display: "block" }}
          maxLength={config.string.title}
        />

        {/* description */}
        <label
          htmlFor="desc_field"
          aria-hidden="true"
          style={{ marginTop: "1.5em", display: "block" }}
        >
          {language.labels.communities.enter_description}
        </label>
        {errors.description && (
          <div className="errtext" role="alert">
            {errors.description}
          </div>
        )}
        <TextareaAutosize
          id="desc_field"
          value={states.description}
          onChange={(e) => {
            states.setDescription(e.target.value);
          }}
          placeholder={language.labels.communities.community_description}
          aria-placeholder={language.labels.communities.community_description}
          minRows="3"
          style={{ width: "100%", maxWidth: "44rem" }}
          maxLength={config.string.paragraph}
        />

        {/* crest */}
        <div style={{ marginTop: "1.5em" }}>
          {language.labels.communities.community_image}
        </div>
        {errors.image && (
          <div className="errtext" role="alert">
            {errors.image}
          </div>
        )}
        <ImageEditor
          setFile={setFile}
          language={language}
          file={file}
          style={{ margin: "0.5em 0", width: "100%", maxWidth: "44rem" }}
          ref={canvas_ref}
        />

        {/* submit button */}
        {errors.submit && (
          <div
            style={{ fontWeight: 600, marginBottom: "1rem" }}
            className="errtext"
            role="alert"
          >
            {errors.submit}
          </div>
        )}
        <div style={{ margin: "2em 0" }}>
          <button className="button" type="submit" ref={submit_button}>
            {language.labels.app.action_create}
          </button>

          <div
            aria-hidden="true"
            style={{ display: "inline-block", width: "0.25em" }}
          ></div>

          {/* reset button */}
          <button className="button-cancel" type="reset">
            {language.labels.app.action_reset}
          </button>
        </div>

        {/* reset alert - accessibility only */}
        <div role="alert" className="sr-only">
          {resetAlert}
        </div>
      </form>
    </div>
  );
};

export default Details;
