import { React, useState, useEffect } from "react";
import config from "../../../../config";
import validator from "../../../../lib/validation";
import axiosCall from "../../../../lib/axios";

import { Policies } from "../../../widgets";

const Uname = ({
  checkPolicies,
  language,
  signout,
  animation,
  next,
  uname,
  setUname,
}) => {
  //////////// INITIALIZATION ///////////
  const [errMsg, setErrMsg] = useState("");
  const [reservedErr, setReservedErr] = useState(false);

  useEffect(() => {
    if (animation && animation.step === 0) {
      if (animation.direction === 1) {
        document.getElementById("Uname").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("Uname").style.animationName = "slideout";
        document.getElementById("Uname").style.animationDirection = "reverse";
      }
    }
  }, []);

  //////////// USER INTERACTIONS ///////////
  const proceed = () => {
    document.getElementById("Uname").style.animationName = "slideout";
    next({ step: 1, direction: 1 });
  };

  const nextStep = async (e) => {
    e.preventDefault();

    //reset error message
    setErrMsg("");
    setReservedErr(false);

    //check uname
    let _uname = uname.toLowerCase().trim();
    var uname_chk = validator.usernameValidator(_uname);
    if (!uname_chk) {
      setErrMsg(language.labels.authentication.username_invalid);
      return;
    }

    // try and reserve uname
    let result = await axiosCall("community/reserve", { uname: _uname });
    if (result.success) {
      if (result.status === 200) {
        //proceed to next step
        document.getElementById("Uname").style.animationName = "slideout";
        next({ step: 1, direction: 1 });
      } else {
        setErrMsg(language.labels.error.community_taken);
        setReservedErr(true);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      nextStep(e);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// RENDER GUI //////////////////
  return (
    <div className="mobile-margin" style={{ position: "relative" }}>
      <form
        className="block-step page-section mobile-margin"
        id="Uname"
        onSubmit={(e) => nextStep(e)}
        aria-label={language.labels.aria.form.new_community}
        style={{ maxWidth: "44rem" }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: language.labels.communities.about,
          }}
        ></div>

        <div style={{ marginTop: "1em" }}>
          {language.labels.communities.uname_reserve}
        </div>

        <input
          id="uname_field"
          value={uname}
          onChange={(e) => {
            setUname(e.target.value);
          }}
          placeholder={language.labels.communities.uname}
          aria-placeholder={language.labels.communities.uname}
          style={{
            width: "100%",
            maxWidth: "44rem",
            display: "block",
            marginTop: "1em",
          }}
          maxLength={config.string.title}
        />
        {errMsg && (
          <div className="errtext" role="alert">
            {errMsg}
          </div>
        )}
        {reservedErr && (
          <div
            style={{ fontSize: "0.7em", fontWeight: 300, marginTop: "0.5em" }}
            role="alert"
          >
            {language.labels.error.community_reservations}
          </div>
        )}

        <div style={{ display: "flex", margin: "2em 0" }}>
          <button className="button" type="submit">
            {language.labels.app.action_next}
          </button>
        </div>

        {/* <Policies
          props={{ community: true }}
          checkPolicies={checkPolicies}
          language={language}
        /> */}
      </form>
    </div>
  );
};

export default Uname;
