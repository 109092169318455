import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const Hub = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const auth = useSelector((state) => state.authentication.value);
  const communities = useSelector((state) => state.communities.value);

  useEffect(() => {
    let invitation = sessionStorage.getItem("invitation");
    let redirection = cookies.get("redirection");

    if (invitation) {
      //go to invitation
      navigate("/invitation");
    } else if (redirection) {
      //redirect
      cookies.remove("redirection");
      if (redirection.page) {
        //get state
        if (redirection.state) {
          navigate("/" + redirection.page, { state: redirection.state });
        } else {
          navigate("/" + redirection.page);
        }
      }
    } else {
      if (auth.community_id && auth.role) {
        navigate("/mycommunity");
      } else if (communities.length > 1) {
        navigate("/communityhub");
      } else {
        navigate("/welcome");
      }
    }
  }, [auth]);

  return <></>;
};

export default Hub;
