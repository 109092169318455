import React, { useState, useEffect } from "react";
import { ContentList } from "../widgets";

const Knowledge = ({
  language,
  main_focus,
  pageHeight,
  pageWidth,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const [thumb_height, set_thumb_height] = useState(190);
  //fix per_page as temporary work around as mobile scrolling problematic
  const [per_page] = useState(200);
  // const [per_page, set_per_page] = useState(
  //   Math.ceil(window.innerHeight / thumb_height)
  // );

  // useEffect(() => {
  //   set_per_page(Math.ceil(pageHeight / thumb_height));
  // }, [pageHeight, thumb_height]);

  useEffect(() => {
    if (pageWidth < 500) set_thumb_height(310);
  }, [pageWidth]);

  //////////// RENDER GUI ///////////
  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <ContentList
        language={language}
        api={"community/threads"}
        content={"knowledge"}
        page_title={language.labels.navigation.knowledge}
        new_link={"/newknowledge"}
        thumb_height={thumb_height}
        per_page={per_page}
        pageHeight={pageHeight}
        signout={signout}
      />
    </main>
  );
};

export default Knowledge;
