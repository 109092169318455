import axios from "axios";
import config from "../../config";
import refreshToken from "../refreshToken";

const switchCommunity = async (cid) => {
  document.getElementById("loader").style.display = "inline";
  const configurationObject = {
    url: `${config.server.api}/community/switch/hub/${localStorage.getItem(
      "client_id"
    )}`,
    method: "POST",
    data: { community_id: cid },
    withCredentials: true,
    credentials: "include",
  };

  try {
    let response = await axios(configurationObject);
    document.getElementById("loader").style.display = "none";
    if (response.status === 202) {
      let r = await refreshToken();
      if (r) {
        return "refresh";
      } else {
        //refresh token expired
        return "error";
      }
    }
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(`LOGGED ERROR: \n`, error);
    document.getElementById("loader").style.display = "none";
    return "error";
  }
};

export default switchCommunity;
