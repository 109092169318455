const shiftGridFocus = (container, contents, matrix, size) => {
  //n where to move: 1 = one place; 2 = up/down; 3 = abs start/end
  let n = matrix[0];

  // direction: -1 = decrease position; 1 = increase position
  let d = matrix[1];

  //get grid dimensions
  let thumbwidth = Math.min(size, window.innerWidth);
  let cols = Math.floor(container.offsetWidth / thumbwidth);
  let rows = Math.ceil(contents.length / cols);
  if (size > window.innerWidth - 10) {
    cols = 1;
    rows = contents.length;
  }

  //find current position in list
  let pos = -1;
  var j = 0,
    len = contents.length;
  while (j < len) {
    if (contents[j] === document.activeElement) {
      pos = j;
      j = len;
    }
    j++;
  }

  //get position in grid
  let row = Math.floor(pos / cols);
  let col = pos - row * cols;

  //return nothing if not in the list
  if (pos < 0) return;

  if (n === 1) {
    //move left or right
    pos = pos + n * d;
  } else if (n === 2) {
    //move up or down
    row = row + d;
    if (row < 0) row = 0;
    if (row > rows) row = rows;
    let _pos = row * cols + col;
    if (_pos < len) pos = _pos;
  } else {
    //moveto start or end
    if (d > 0) {
      pos = len - 1;
    } else {
      pos = 0;
    }
  }

  //adjust for end-points
  if (pos <= 0) pos = 0;
  if (pos >= len) pos = len - 1;

  //return element
  return contents[pos];
};

//allow accessibilty users to navigate a 'grid' of thumbnails
const navGrid = (container, contents, key, size = 210) => {
  let matrix;

  // Left - back one place
  if (key === "ArrowLeft") matrix = [1, -1];

  // Right - forward one place
  if (key === "ArrowRight") matrix = [1, 1];

  // Up - up one row
  if (key === "ArrowUp") matrix = [2, -1];

  // Down - down one row
  if (key === "ArrowDown") matrix = [2, 1];

  // Home - first cell in row
  if (key === "Home" || key === "PageUp" || key === "CTRL+Home")
    matrix = [3, -1];

  // End - last cell in row
  if (key === "End" || key === "PageDown" || key === "CTRL+End")
    matrix = [3, 1];

  let _new = shiftGridFocus(container, contents, matrix, size);
  if (_new) _new.focus();
};

export default navGrid;
