import React, { useState, useEffect } from "react";
import KnowledgeThread from "./thread";
import AddAnswer from "./answer";
import Modal from "../../modal/Modal";
import axiosCall from "../../../lib/axios";
import Card from "../../../lib/previewLinks";

const KnowledgeViewer = ({
  community,
  style,
  language,
  currentThread,
  onClose,
  onChange,
  onDelete,
  removedAnswer,
  bite,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const [thread, setThread] = useState({});
  const [answers, setAnswers] = useState([]);
  const [HTML, setHTML] = useState("");
  const [addAnswer, setAddAnswer] = useState(false);
  const [editor, setEditor] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmClosure, setConfirmClosure] = useState(false);

  useEffect(() => {
    getThread();
  }, []);

  useEffect(() => {
    if (removedAnswer) {
      let _answers = new Array();
      for (var i = 0; i < answers.length; i++) {
        if (answers[i]._id !== removedAnswer) _answers.push(answers[i]);
        if (i === answers.length - 1) setAnswers(_answers);
      }
    }
  }, [removedAnswer]);

  //////////// THREAD ///////////
  const getThread = async () => {
    const data = { kid: currentThread };
    document.getElementById("loader").style.display = "inline";
    let result = await axiosCall("knowledge/thread", data);
    if (result.success) {
      if (result.data?.main) {
        setThread(result.data.main);
        if (result.data.answers) setAnswers(result.data.answers);
        fixHTML(result.data.main);
      } else {
        onClose();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      getThread();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const processLinksMeta = async (meta, post, obj, card, processed) => {
    let _post = post;
    for (const _obj of meta) {
      if (_obj.link === obj.link) {
        processed.push(obj.link);
        post = post.replaceAll(obj.link, card);
        _post = _post.replaceAll(obj.link, card);
      } else if (processed.indexOf(_obj.link) < 0) {
        post = post.replaceAll(_obj.link, _obj.card);
      }
    }
    return { post, _post, processed };
  };

  const updateLinksMeta = async (meta, post) => {
    let processed = [];
    for (const obj of meta) {
      const result = await axiosCall(
        "knowledge/preview/link",
        { link: obj.link },
        false
      );
      const card = await Card({ ...result.data, link: obj.link });
      const p = await processLinksMeta(meta, post, obj, card, processed);
      processed = p.processed;
      post = p._post;
      setHTML(p.post);
    }
  };

  const fixHTML = async (data) => {
    if (data.links && data.links.length > 0) {
      let html = data.post;
      let meta = [];
      for (const link of data.links) {
        const card = await Card({ link });
        meta.push({ link, card });
        html = html.replaceAll(link, card);
      }
      setHTML(html);
      updateLinksMeta(meta, data.post);
    } else {
      setHTML(data.post);
    }
  };

  const deleteThread = async () => {
    let result = await axiosCall("knowledge/withdraw", {
      kid: currentThread,
    });
    if (result.success) {
      setDeleteModal(false);
      onDelete();
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteThread();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// RENDER GUI ///////////
  return (
    <div className="thread" style={style}>
      {/* back */}
      {!editor._id && bite && (
        <button
          className="link"
          style={{ marginBottom: "1em" }}
          onClick={() => onClose()}
        >
          {language.labels.bites.knowledge_all}
        </button>
      )}

      <div style={{ marginTop: "0em" }}>
        <KnowledgeThread
          community={community}
          language={language}
          thread={thread}
          HTML={HTML}
          answers={answers}
          addAnswer={addAnswer}
          setAddAnswer={setAddAnswer}
          editor={editor}
          setEditor={setEditor}
          getThread={getThread}
          onChange={onChange}
          setDeleteModal={setDeleteModal}
          bite={bite}
          signout={signout}
        ></KnowledgeThread>

        {addAnswer && (
          <AddAnswer
            thread={thread}
            language={language}
            signout={signout}
            setAddAnswer={setAddAnswer}
            onChange={onChange}
            getThread={getThread}
          ></AddAnswer>
        )}
      </div>

      {/* DELETE KNOWLEDGE MODAL */}
      <Modal
        className="modal-dynamic"
        title={language.labels.knowledge.delete[thread.knowledge_type]}
        show={deleteModal}
      >
        <div
          style={{ fontSize: "1.1em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.app.delete_warning,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: ".9rem",
            marginTop: "1rem",
          }}
        >
          <input
            id="consequence_understood"
            type={"checkbox"}
            onChange={(e) => setConfirmClosure(e.target.checked)}
            defaultChecked={confirmClosure}
          />
          <div style={{ minWidth: "0.5rem" }}></div>
          <label className="hover" htmlFor="consequence_understood">
            {language.labels.app.consequences_understood}
          </label>
        </div>
        {confirmClosure && (
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <button className="button" onClick={() => deleteThread()}>
              {language.labels.knowledge.delete_now[thread.knowledge_type]}
            </button>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "2em" }}>
          <button className="button-off" onClick={() => setDeleteModal(false)}>
            {language.labels.app.cancel}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default KnowledgeViewer;
