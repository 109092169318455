const Search = ({
  search,
  onKey = () => {},
  onChange,
  placeholder,
  style,
  search_ref,
  filter,
}) => {
  return (
    <div className="web-search" style={style}>
      <div className="glyphs">f</div>
      <input
        ref={search_ref}
        type="search"
        {...(search && { value: search })}
        onKeyUp={(e) => onKey(e)}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        size={20}
      />
      {filter && <button onClick={filter}>|</button>}
    </div>
  );
};

export default Search;
