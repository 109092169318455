import React, { useState, useEffect } from "react";
import { ContentList } from "../widgets";

const VideoHub = ({ language, main_focus, pageHeight, pageWidth, signout }) => {
  //////////// INITIALIZATION ///////////
  const [thumb_height, set_thumb_height] = useState(190);
  //fix per_page as temporary work around as mobile scrolling problematic
  const [per_page] = useState(200);
  // const [per_page, set_per_page] = useState(
  //   Math.ceil(window.innerHeight / thumb_height)
  // );

  // useEffect(() => {
  //   set_per_page(Math.ceil(pageHeight / thumb_height));
  // }, [pageHeight, thumb_height]);

  useEffect(() => {
    if (pageWidth < 500) set_thumb_height(310);
  }, [pageWidth]);

  //////////// RENDER GUI ///////////
  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <ContentList
        language={language}
        api={"community/videos"}
        content={"videos"}
        page_title={language.labels.navigation.videos}
        new_link={"/newvideo"}
        thumb_height={thumb_height}
        per_page={per_page}
        signout={signout}
      />
    </main>
  );
};

export default VideoHub;
