import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { setCommunities } from "../../features/communities";

import config from "../../config";
import { CommunityPublic, PubBar } from "../widgets";
import axiosCall from "../../lib/axios";
import { sortArrObj } from "../../lib/common";

const Invitation = ({ language, signout }) => {
  ///////////////// INITIALIZE /////////////////
  const auth = useSelector((state) => state.authentication.value);
  const communities = useSelector((state) => state.communities.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [cid, setCid] = useState("");
  const [community, setCommunity] = useState({});
  const [invitation, setInvitation] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [member, setMember] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem("invitation")) {
      let params = new URLSearchParams(window.location.search);
      let obj = {};
      for (const p of params) {
        if (p[0] === "c") {
          obj.cid = p[1];
        } else if (p[0] === "i") {
          obj.code = p[1];
        }
      }
      if (obj.cid && obj.code) {
        sessionStorage.setItem("invitation", JSON.stringify(obj));
        setCid(obj.cid);
        setCode(obj.code);
      } else {
        setErrMsg(language.labels.error.no_invite);
      }
    } else {
      const _obj = JSON.parse(sessionStorage.getItem("invitation"));
      if (_obj.cid && _obj.code) {
        setCid(_obj.cid);
        setCode(_obj.code);
      } else {
        setErrMsg(language.labels.error.no_invite);
      }
    }
  }, []);

  useEffect(() => {
    if (code && cid) {
      getInvitation(code, cid);
    }
  }, [code, cid]);

  ///////////////// FUNCTIONS /////////////////
  //check membership
  const checkMembership = async (cid) => {
    if (communities) {
      let community = false;
      for (let i = 0; i < communities.length; i++) {
        if (communities[i].id === cid) {
          community = true;
          break;
        }
      }
      return community;
    } else {
      return false;
    }
  };

  //get invitation
  const getInvitation = async (code, cid) => {
    try {
      const _member = await checkMembership(cid);
      setMember(_member);

      const configurationObject = {
        url: config.server.api + "/invite/view",
        method: "POST",
        data: { code, cid },
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        setInvitation(response.data.invitation);
        setCommunity(response.data.community);
      } else {
        setErrMsg(language.labels.error.no_invite);
        sessionStorage.removeItem("invitation");
      }
    } catch (err) {
      setErrMsg(language.labels.error.unknown);
      document.getElementById("loader").style.display = "none";
      sessionStorage.removeItem("invitation");
    }
  };

  const accept = async () => {
    let result = await axiosCall("invite/accept", { code, cid });
    if (result.success) {
      sessionStorage.removeItem("invitation");
      setAccepted(true);
      if (result.status === 200) {
        //update communities
        let comm = result.data.community;
        let newcomm = {
          role: result.data.role,
          id: comm._id,
          title: comm.title,
          type: comm.type,
          uname: comm.uname,
          crest: comm.crest,
          description: comm.description,
          points: comm.points || 0,
          private: comm.private || false,
        };
        if (comm.exclusive) newcomm.exclusive = comm.exclusive;
        if (comm.verified) newcomm.verified = comm.verified;
        if (comm.website) newcomm.private = comm.website;

        let _communities = [...communities];
        _communities.push(newcomm);
        _communities.sort(sortArrObj("uname", language.lang));
        dispatch(setCommunities(_communities));
        localStorage.setItem("communities", JSON.stringify(_communities));
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      accept();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const decline = async (home = false) => {
    try {
      const configurationObject = {
        url: config.server.api + "/invite/decline",
        method: "POST",
        data: { code, cid },
      };
      document.getElementById("loader").style.display = "inline";
      await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      sessionStorage.removeItem("invitation");
      if (home) {
        navigate("/");
      } else {
        setDeclined(true);
      }
    } catch (err) {
      setErrMsg(language.labels.error.unknown);
      document.getElementById("loader").style.display = "none";
      sessionStorage.removeItem("invitation");
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <>
      {!auth.account_type && <PubBar language={language} tabIndex={0} />}
      <main
        className={auth.account_type ? "main-page" : "public"}
        tabIndex={0}
        style={{ paddingBottom: "2em" }}
      >
        <div
          className={auth.account_type ? "page-section" : "public-section"}
          style={{ marginTop: `${auth.account_type ? "0" : "1em"}` }}
        >
          {/* heading */}
          <div
            className={auth.account_type ? "mobile-justify" : "web-justify"}
            style={{
              display: "flex",
              fontWeight: 500,
              gap: "0.5em",
              alignItems: "center",
            }}
          >
            <div className="glyphs font-yongo" style={{ fontSize: "2em" }}>
              E
            </div>
            <div style={{ fontSize: "1.4em" }}>
              {language.labels.app.invitation}
            </div>
          </div>

          {errMsg && (
            <div
              className={
                auth.account_type
                  ? "errtext mobile-center"
                  : "errtext web-center"
              }
              style={{ marginTop: "2em" }}
            >
              <div>{errMsg}</div>
              <button
                className="button"
                style={{ marginTop: "2em" }}
                onClick={() => navigate("/")}
              >
                {language.labels.app.ok}
              </button>
            </div>
          )}

          {!errMsg && community._id && invitation._id && (
            <>
              <div
                className={auth.account_type ? "mobile-center" : "web-center"}
                style={{ marginTop: "1em" }}
              >
                {language.labels.invitation.invited[invitation.role].replace(
                  /{community}/g,
                  community.uname
                )}
              </div>

              {/* community public details */}
              <CommunityPublic community={community} language={language} />

              {/* accept / decline */}
              {!accepted && !declined && !member && (
                <div
                  className={auth.account_type ? "mobile-center" : "web-center"}
                  style={{ marginTop: "2em" }}
                >
                  {auth.account_type ? (
                    <button className="button" onClick={accept}>
                      {language.labels.invitation.accept}
                    </button>
                  ) : (
                    <>
                      <div>{language.labels.invitation.accept_join}</div>
                      <button
                        className="button"
                        style={{ marginTop: "1em" }}
                        onClick={() => navigate("/login")}
                      >
                        {language.labels.authentication.signin.yongo}
                      </button>

                      <div className="join-container">
                        <h2 className="heading2">
                          {language.labels.authentication.no_account}
                        </h2>
                        <div
                          role="link"
                          className="link"
                          style={{ padding: "0 0.25em" }}
                          onClick={() => navigate(`/signup`)}
                          onKeyUpCapture={(e) =>
                            e.key === "Enter" && navigate("/signup")
                          }
                          tabIndex={0}
                        >
                          {language.labels.authentication.signup.join}
                        </div>
                      </div>
                    </>
                  )}

                  {/* decline */}
                  <div style={{ marginTop: "2em" }}>
                    <button className="button-cancel" onClick={decline}>
                      {language.labels.invitation.decline}
                    </button>
                  </div>
                </div>
              )}

              {member && (
                <div
                  className={auth.account_type ? "mobile-center" : "web-center"}
                  style={{ marginTop: "2em" }}
                >
                  <div>{language.labels.invitation.member}</div>
                  <div style={{ marginTop: "1em" }}>
                    <button className="button" onClick={() => decline(true)}>
                      {language.labels.app.ok}
                    </button>
                  </div>
                </div>
              )}

              {(accepted || declined) && (
                <div
                  className={auth.account_type ? "mobile-center" : "web-center"}
                  style={{ marginTop: "2em" }}
                >
                  <div>
                    {accepted
                      ? language.labels.invitation.accepted
                      : language.labels.invitation.declined}
                  </div>
                  <button
                    className="button"
                    style={{ marginTop: "1em" }}
                    onClick={() => navigate("/")}
                  >
                    {language.labels.app.ok}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default Invitation;
