import { useNavigate } from "react-router-dom";

const PubNav = ({ language, selected }) => {
  const navigate = useNavigate();

  return (
    <div
      className="public"
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1em 0.5em",
        gap: "2em",
      }}
    >
      <div
        role="link"
        className={selected === "yongo" ? "public-nav on" : "public-nav hover"}
        onClick={() => navigate("/yongo")}
        onKeyUpCapture={(e) => e.key === "Enter" && navigate("/yongo")}
        tabIndex={0}
      >
        {language.labels.public.yongo}
      </div>
      <div
        role="link"
        className={
          selected === "benefits" ? "public-nav on" : "public-nav hover"
        }
        onClick={() => navigate("/benefits")}
        onKeyUpCapture={(e) => e.key === "Enter" && navigate("/benefits")}
        tabIndex={0}
      >
        {language.labels.public.benefits}
      </div>
      {/* Parked for now

      <div
        role="link"
        className={
          selected === "find_communities" ? "public-nav on" : "public-nav hover"
        }
        onClick={() => navigate("/find_community")}
        onKeyUpCapture={(e) => e.key === "Enter" && navigate("/find_community")}
        tabIndex={0}
      >
        {language.labels.navigation.communities.toUpperCase()}
      </div> */}
      {/* Parked until release 1.1
      <div
        role="link"
        className={selected === "plans" ? "public-nav on" : "public-nav hover"}
        onClick={() => navigate("/plans")}
        onKeyUpCapture={(e) => e.key === "Enter" && navigate("/plans")}
        tabIndex={0}
      >
        {language.labels.public.plans}
      </div> */}
      <div
        role="link"
        className={selected === "get" ? "public-nav on" : "public-nav hover"}
        onClick={() => navigate("/getyongo")}
        onKeyUpCapture={(e) => e.key === "Enter" && navigate("/getyongo")}
        tabIndex={0}
      >
        {language.labels.public.get}
      </div>
    </div>
  );
};

export default PubNav;
