import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosCall from "../../lib/axios";

import config from "../../config";

const NewPathway = ({ language, signout, main_focus }) => {
  //////////// INITIALIZATION ///////////
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);

  const topicRef = useRef();
  const navigate = useNavigate();
  const [topic, setTopic] = useState("");
  const [topicErr, setTopicErr] = useState("");

  ////////////// PROCESS DATA /////////////////

  const checkTopic = () => {
    setTopicErr("");
    if (topic.trim() === "") {
      setTopicErr(language.labels.bites.error.bite);
      topicRef.current.focus();
    } else {
      suggestBites(topic.trim());
    }
  };

  const suggestBites = async (topic) => {
    //call api
    let result = await axiosCall("pathway/create", { topic });

    if (result.success) {
      navigate("/pathway", { state: { pid: result.data } });
    } else if (result.refresh) {
      //token has been refreshed, try again
      suggestBites(topic);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// GUI /////////////////
  if (auth.level < 2) {
    return <></>;
  } else {
    return (
      <main className="main-page" ref={main_focus} tabIndex="0">
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                community.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + community.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.pathways.add.one}
              </h1>
              <div className="handle2">{community.title}</div>
            </div>
          </div>
        </div>

        <div className="page-section" style={{ maxWidth: "66rem" }}>
          <div
            style={{ marginTop: "0.5em" }}
            dangerouslySetInnerHTML={{
              __html: language.labels.pathways.about,
            }}
          />

          {/* help generate topics */}
          <button
            style={{
              alignSelf: "flex-start",
              marginTop: "0.5em",
              fontWeight: "500",
            }}
            className="link"
            onClick={() => {
              navigate("/theme");
            }}
          >
            {language.labels.ai.help}
          </button>
        </div>

        {/* create new pathway */}
        <div className="page-section" style={{ maxWidth: "44rem" }}>
          <h2
            className="heading2"
            style={{
              fontWeight: "bold",
              fontSize: "1.1em",
              marginTop: "1em",
            }}
          >
            {language.labels.pathways.create}
          </h2>
          <input
            ref={topicRef}
            style={{ marginTop: "0.5em", width: "100%" }}
            placeholder={language.labels.tips.enter}
            aria-placeholder={language.labels.tips.enter}
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            maxLength={config.string.title}
          />
          {topicErr && (
            <div className="errtext" role="alert">
              {topicErr}
            </div>
          )}
          <div style={{ display: "flex", gap: "0.25em" }}>
            <button
              className="button-secondary"
              style={{ margin: "1em 0" }}
              onClick={() => navigate("/pathways")}
            >
              {language.labels.app.cancel}
            </button>
            <button
              className="button"
              style={{ margin: "1em 0", alignSelf: "flex-start" }}
              onClick={() => checkTopic()}
            >
              {language.labels.app.action_create}
            </button>
          </div>
        </div>
      </main>
    );
  }
};

export default NewPathway;
