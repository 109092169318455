import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { GetApp, ImageEditor } from "../widgets";
import axiosCall from "../../lib/axios";
import validator from "../../lib/validation";
import { naturalDate } from "../../lib/date";
import config from "../../config";
import Modal from "../modal/Modal";

const Chat = ({ os, language, main_focus, signout }) => {
  //////////// INITIALIZE ///////////
  const community = useSelector((state) => state.context.value);

  const { state } = useLocation();
  const id = state.id;

  const [chat, setChat] = useState({});
  const [title, setTitle] = useState("");
  const [avatar, setAvatar] = useState("");

  const [messages, setMessages] = useState(0);
  const [announcements, setAnnouncements] = useState(0);
  const [systems, setSystems] = useState(0);
  const [showAvatar, setShowAvatar] = useState(false);
  const [file, setFile] = useState(null);
  const [titleErr, setTitleErr] = useState("");
  const canvas_ref = useRef();

  const [suspendModal, setSuspendModal] = useState(false);

  useEffect(() => {
    if (!chat._id) getConversation();
  }, []);

  useEffect(() => {
    if (chat._id) document.activeElement.blur();
  }, [chat]);

  //////////// DATA FUNCTIONS ///////////

  const getConversation = async () => {
    let result = await axiosCall("topic/info", { id });
    if (result.success) {
      setChat(result.data.chat);
      setTitle(result.data.chat.topicName);
      setAvatar(result.data.chat.avatar);
      //update counts
      for (var i = 0; i < result.data.counts.length; i++) {
        if (result.data.counts[i]._id === "message") {
          setMessages(result.data.counts[i].count);
        } else if (result.data.counts[i]._id === "announcement") {
          setAnnouncements(result.data.counts[i].count);
        } else if (result.data.counts[i]._id === "system") {
          setSystems(result.data.counts[i].count);
        }
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      getConversation();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const resetAvatar = () => {
    setShowAvatar(false);
    setFile(null);
  };

  const changeAvatar = async (e, change = true) => {
    let click = true;
    if (e.key) click = false;
    let canvas = canvas_ref.current;
    if (!canvas || !file || !change) {
      resetAvatar();
      if (!click) document.getElementById("toggle_image_editor").focus();
      return;
    }

    var data_uri = canvas.toDataURL();
    // try and submit data
    let result = await axiosCall("topic/image", {
      id,
      avatar: data_uri,
    });
    if (result.success) {
      if (result.status === 200) {
        let ts = new Date();
        let _chat = {
          ...chat,
          avatar: result.data,
          timestamp: ts.toISOString(),
        };
        setChat(_chat);
        setAvatar(result.data);
        setSystems(systems + 1);
        resetAvatar();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeTitle();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const changeTitle = async () => {
    setTitleErr("");

    //title can't be empty
    if (title.trim() === "") {
      setTitleErr(language.labels.chat.error.title);
      return;
    }

    //no change
    if (title.trim() === chat.topicName) {
      document.activeElement.blur();
      return;
    }

    // try and submit data
    let result = await axiosCall("topic/rename", {
      id,
      topicName: title.trim(),
    });
    if (result.success) {
      if (result.status === 200) {
        let ts = new Date();
        let _chat = {
          ...chat,
          topicName: title.trim(),
          timestamp: ts.toISOString(),
        };
        setChat(_chat);
        setTitle(title.trim());
        setSystems(systems + 1);
      } else if (result.data?.message === "DUPLICATE") {
        setTitleErr(language.labels.chat.already);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeTitle();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const suspend = async (reopen = false) => {
    // try and submit data
    let data = { id };
    if (reopen === true) data.reopen = true;
    let result = await axiosCall("topic/suspend", data);
    if (result.success) {
      if (result.status === 200) {
        let ts = new Date();
        let _chat = {
          ...chat,
          timestamp: ts.toISOString(),
          active: result.data,
        };
        setChat(_chat);
      }
      setSuspendModal(false);
    } else if (result.refresh) {
      //token has been refreshed, try again
      suspend();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// MISC FUNCTIONS ///////////
  function toggleImageEditor() {
    if (showAvatar) setFile(null);
    setShowAvatar(!showAvatar);
  }

  const setBlur = () => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (document.activeElement.getAttribute("name") !== "title_field") {
        setTitle(chat.topicName);
        setTitleErr("");
      }
    }, 100);
  };

  //////////// RENDER GUI ///////////

  if (chat.community && community.id == chat.community) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        {/* heading */}
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                community.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + community.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.navigation.chat}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {community.title}
              </div>
            </div>
          </div>
        </div>

        {/* Download app */}
        <div className="page-section">
          <div style={{ margin: "0.5em 0" }}>
            {os === "desktop"
              ? language.labels.chat.get_app.desktop
              : language.labels.chat.get_app.mobile}
          </div>
          <GetApp os={os} />
          <div
            style={{ marginTop: "0.5em", fontSize: "0.8em", fontWeight: 300 }}
          >
            {language.labels.public.limited}
          </div>
        </div>

        <div
          className="page-section"
          style={{ maxWidth: "44rem", marginTop: "0.5em" }}
        >
          {/* AVATAR */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "44rem",
            }}
          >
            <label className="text-label">{language.labels.chat.image}</label>
            <div
              className="thumb-block border"
              style={{
                backgroundImage: `url("${config.content.server + avatar}")`,
                filter: `${chat.active ? "none" : "grayscale(0.9)"}`,
                opacity: `${chat.active ? 1 : 0.7}`,
                marginTop: "2px",
              }}
            >
              {chat.active && (
                <button
                  id="toggle_image_editor"
                  className="button-edit left50"
                  style={{
                    padding: "0 .5em",
                    position: "absolute",
                    bottom: "-0.5em",
                  }}
                  title={language.labels.app.change}
                  aria-label={language.labels.app.change}
                  onClick={() => {
                    toggleImageEditor();
                  }}
                >
                  <div aria-hidden="true" className="glyphs font-contrast">
                    w
                  </div>
                  <div style={{ padding: "0 .2rem" }}></div>
                  <div style={{ flexGrow: 0 }}>
                    {language.labels.app.change}
                  </div>
                </button>
              )}
            </div>
          </div>

          {showAvatar && (
            <ImageEditor
              style={{ maxWidth: "44rem", marginTop: "5px" }}
              file={file}
              setFile={setFile}
              language={language}
              dim={240}
              apply={changeAvatar}
              ref={canvas_ref}
            />
          )}

          {/* TITLE */}
          <label
            className="text-label"
            htmlFor="name_field"
            style={{ display: "inline-block", marginTop: "2em" }}
          >
            {language.labels.chat.title}
          </label>
          <div className="natural-edit" style={{ display: "flex" }}>
            <input
              autoComplete="off"
              style={{ flex: 1 }}
              id="title_field"
              name="title_field"
              value={title}
              aria-placeholder={language.labels.chat.enter}
              placeholder={language.labels.chat.enter}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => setBlur()}
              onKeyDown={(e) => e.key === "Enter" && changeTitle()}
              maxLength={config.string.title}
            />
            <div
              style={{
                flexBasis: "3.5rem",
                display: "flex",
                justifyContent: "space-around",
                margin: "-0.25rem",
              }}
            >
              <div
                role="button"
                className="glyphs accept"
                name="title_field"
                title={language.labels.app.apply}
                aria-label={language.labels.app.apply}
                onBlur={() => setBlur()}
                onClick={() => changeTitle()}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeTitle()
                }
                tabIndex={0}
              >
                *
              </div>
              <div
                role="button"
                className="glyphs reject"
                name="title_field"
                style={{ fontSize: "0.7rem" }}
                title={language.labels.app.discard}
                aria-label={language.labels.app.discard}
                onBlur={() => setBlur()}
                onClick={() => {
                  setTitle(chat.topicName);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") &&
                  setTitle(chat.topicName)
                }
                tabIndex={0}
              >
                x
              </div>
            </div>
          </div>
          {titleErr && (
            <div className="errtext" role="alert">
              {titleErr}
            </div>
          )}
        </div>

        {/* Details */}
        <div className="page-section" style={{ marginTop: "0.5em" }}>
          <label className="text-label">
            {language.labels.app.key_details}
          </label>

          {/* Status */}
          <div
            style={{
              margin: "0.5em 0.25em",
              display: "flex",
              flexDirection: "row",
              gap: "1em",
            }}
          >
            <h2 className="heading2">{language.labels.app.status}</h2>
            {chat.active ? (
              <div className="success" style={{ fontWeight: 500 }}>
                {language.labels.app.active.toUpperCase()}
              </div>
            ) : (
              <div className="alert" style={{ fontWeight: 500 }}>
                {language.labels.app.closed.toUpperCase()}
              </div>
            )}
          </div>

          {/* Count */}
          <div
            style={{
              margin: "0.5em 0.25em",
              display: "flex",
              flexDirection: "row",
              gap: "1em",
            }}
          >
            <h2 className="heading2">{language.labels.chat.posts.total}</h2>
            <div style={{ fontWeight: 500 }}>
              {validator.bigNumber(messages + announcements + systems)}
            </div>
          </div>

          {/* Last */}
          {messages + announcements + systems > 0 && (
            <div
              style={{
                margin: "0.5em 0.25em",
                display: "flex",
                flexDirection: "row",
                gap: "1em",
              }}
            >
              <h2 className="heading2">{language.labels.chat.last}</h2>
              <div style={{ fontWeight: 500 }}>
                {naturalDate(
                  chat.timestamp,
                  language.locale,
                  language.labels.date
                )}
              </div>
            </div>
          )}
        </div>

        {/* suspend / re-open */}
        <div className="page-section">
          {chat.active && (
            <button
              className="button"
              style={{ marginTop: "1em", display: "block" }}
              onClick={() => {
                setSuspendModal(true);
              }}
            >
              {language.labels.chat.close}
            </button>
          )}

          {!chat.active && (
            <>
              <button
                className="button"
                style={{ marginTop: "1em", display: "block" }}
                onClick={() => {
                  suspend(true);
                }}
              >
                {language.labels.chat.reopen}
              </button>
            </>
          )}
        </div>

        {/* Suspend Modal */}
        <Modal
          className="modal-dynamic"
          show={suspendModal}
          title={language.labels.chat.close}
          html={language.labels.chat.warning.close}
          onClose={function () {
            setSuspendModal(false);
          }}
          onOk={suspend}
          language={language}
        ></Modal>
      </main>
    );
  } else {
    return <></>;
  }
};
export default Chat;
