import { React, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PubBar, PubFooter, PubNav, PubPreview } from "../widgets";

const about = require("../../media/about.png");

const Benefits = ({ language }) => {
  ////////////// RENDER GUI //////////////
  const navigate = useNavigate();

  return (
    <main>
      <PubBar language={language} buttons={true} />
      <PubNav language={language} selected={"benefits"} />

      <div className="public section" style={{ paddingBottom: "6em" }}>
        <div style={{ textAlign: "center" }}>
          {language.labels.brand.description}
        </div>
        <img className="public-benefits" src={about} />

        <div className="list-checked">
          <div
            dangerouslySetInnerHTML={{
              __html: language.labels.public.benefits_1,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: language.labels.public.benefits_2,
            }}
          />
        </div>

        {/* screen shots */}
        <PubPreview language={language} style={{ margin: "2em 0" }} />
      </div>

      <PubFooter language={language} />
    </main>
  );
};

export default Benefits;
