import React, { useState, useEffect } from "react";

const Success = ({
  bid,
  title,
  successLabel,
  referredBy,
  language,
  animation,
  topicId,
  navigate,
}) => {
  ////////////// INITIALIZE //////////////////
  const [goText, setGoText] = useState(language.labels.bites.success.go);

  useEffect(() => {
    if (animation && animation.step === 3 && animation.direction === 1) {
      document.getElementById("success").style.animationName = "slidein";
    }

    //go button text
    if (topicId) {
      setGoText(language.labels.bites.creation.go);
    } else if (referredBy) {
      if (referredBy === "knowledge") {
        setGoText(language.labels.knowledge.add);
      } else {
        setGoText(language.labels.videos.add);
      }
    }
  }, []);

  ////////////// NAVIGATION //////////////////
  const go_to_bite = () => {
    if (topicId) {
      navigate("/pathway", { state: { pid: topicId } });
    } else if (referredBy) {
      navigate("/bite", { state: { bid, referredBy } });
    } else {
      navigate("/bite", { state: { bid } });
    }
  };

  ////////////// RENDER GUI //////////////////
  return (
    <div style={{ position: "relative", maxWidth: "44rem" }}>
      <div className="block-step page-section" id="success">
        {!topicId && (
          <div
            style={{ margin: "1em 0" }}
            dangerouslySetInnerHTML={{
              __html: successLabel.replace(/{bite}/g, title),
            }}
          />
        )}

        <button
          className="button"
          style={{
            borderWidth: "2px",
            alignSelf: "flex-start",
            margin: "2em 0",
          }}
          onClick={() => go_to_bite()}
        >
          {goText}
        </button>
      </div>
    </div>
  );
};

export default Success;
