import { React } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { GetApp, PubBar, PubFooter, PubNav } from "../widgets";

const get_individual = require("../../media/get_individual.jpg");
const get_community = require("../../media/get_community.jpg");

const Get = ({ language, os }) => {
  ////////////// RENDER GUI //////////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  return (
    <main>
      <PubBar language={language} buttons={true} />
      <PubNav language={language} selected={"get"} />

      <div className="public section" style={{ paddingBottom: "6em" }}>
        <div className="public-getyongo">
          <div>
            <div style={{ fontWeight: 500 }}>
              {language.labels.public.for_individuals}
            </div>
            <img aria-label={language.labels.public.get} src={get_individual} />
            <div style={{ marginBottom: "2em" }}>
              {language.labels.public.soon}
            </div>
          </div>

          <div>
            <div style={{ fontWeight: 500 }}>
              {language.labels.public.for_communities}
            </div>
            <img aria-label={language.labels.public.get} src={get_community} />
            <div style={{ marginBottom: "2em" }}>
              {language.labels.public.hub}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "1em", textAlign: "center" }}>
          {auth.account_type &&
          auth.account_type !== "suspended" &&
          auth.agree_terms === "ok" ? (
            <button className="link" onClick={() => navigate("/")}>
              {language.labels.public.use_now}
            </button>
          ) : (
            <>
              <div style={{ fontWeight: 500, marginBottom: "1em" }}>
                {language.labels.public.now}
              </div>
              <button className="button" onClick={() => navigate("/signup")}>
                {language.labels.authentication.signup.join}
              </button>
            </>
          )}
        </div>

        {/* <GetApp os={os} myclass={"public-center"} /> */}
      </div>

      <PubFooter language={language} />
    </main>
  );
};

export default Get;
