import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Uname, Details } from "./communitySteps";

const NewCommunity = ({ checkPolicies, language, signout, main_focus }) => {
  ////////////// INITIALIZATION //////////////////
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState([]);

  const def_focus = useRef();

  //uname, title and description
  const [uname, setUname] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  ////////////// STEPS /////////////////
  const next = (step) => {
    //check we haven't timed out first
    // checkUser();

    setNextStep(step);
    setTimeout(() => {
      setCurrentStep(step.step);
      setNextStep(null);
    }, 300);
  };
  const allSteps = [
    <Uname
      checkPolicies={checkPolicies}
      language={language}
      signout={signout}
      animation={nextStep}
      next={next}
      uname={uname}
      setUname={setUname}
      def_focus={def_focus}
    />,
    <Details
      language={language}
      signout={signout}
      animation={nextStep}
      next={next}
      navigate={navigate}
      states={{ uname, title, setTitle, description, setDescription }}
    />,
  ];

  return (
    <main className="main-page" ref={main_focus} tabIndex="0">
      <div className="page-section mobile-justify" style={{ display: "flex" }}>
        <h1 className="heading">
          {language.labels.communities.start_community}
        </h1>
      </div>
      <div>
        {allSteps[currentStep]}
        {nextStep && allSteps[nextStep.step]}
      </div>
    </main>
  );
};

export default NewCommunity;
