import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axiosCall from "../../lib/axios";
import { naturalDate, recentDate } from "../../lib/date";
import config from "../../config";
import { ChatCreator, Search } from "../widgets";

const Chats = ({ language, signout, main_focus }) => {
  ////////////////// INITIALIZE //////////////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const community = useSelector((state) => state.context.value);

  const search_ref = useRef();

  const [allChats, setAllChats] = useState([]);
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState("chats");

  const [status, setStatus] = useState("all");
  const [posted, setPosted] = useState("any");
  const [term, setTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getChats();
  }, []);

  const getChats = async () => {
    let result = await axiosCall("community/chats");
    if (result.success) {
      setAllChats(result.data);
      setChats(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getChats();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// FILTER SEARCH ///////////
  const searchChats = () => {
    if (search_ref.current?.value) {
      onFilter(search_ref.current.value, "SEARCH");
    } else {
      onFilter("", "SEARCH");
    }
  };

  const toggleFilter = () => {
    if (showFilter) {
      setStatus("all");
      setPosted("any");
      setTerm(search_ref.current.value);
      let _chats = [...allChats];
      let filtered = _chats.filter((chat) =>
        chat.topicName
          .toLowerCase()
          .includes(search_ref.current.value.toLowerCase())
      );
      setChats(filtered);
    }
    setShowFilter(!showFilter);
  };

  const filterStatus = (e) => {
    if (status === e.value) {
      setStatus("all");
      onFilter("all", "STATUS");
    } else {
      setStatus(e.value);
      onFilter(e.value, "STATUS");
    }
  };

  const filterPosted = (e) => {
    if (posted === e.value) {
      setPosted("any");
      onFilter("any", "POSTED");
    } else {
      setPosted(e.value);
      onFilter(e.value, "POSTED");
    }
  };

  const onFilter = async (val, flag) => {
    let _filter = { term, status, posted };
    if (flag === "SEARCH") {
      setTerm(val);
      _filter.term = val.trim();
    } else if (flag === "STATUS") {
      setStatus(val);
      _filter.status = val;
    } else if (flag === "POSTED") {
      setPosted(val);
      _filter.posted = val;
    }
    let _chats = [...allChats];
    let filtered = _chats.filter(
      (chat) =>
        chat.topicName.toLowerCase().includes(_filter.term.toLowerCase()) &&
        (_filter.status === "active" ? chat.active : true) &&
        (_filter.status === "closed" ? !chat.active : true) &&
        (_filter.posted === "week"
          ? chat.message && recentDate(chat.timestamp, "day", 7)
          : true) &&
        (_filter.posted === "month"
          ? chat.message && recentDate(chat.timestamp, "month", 1)
          : true) &&
        (_filter.posted === "semester"
          ? chat.message && recentDate(chat.timestamp, "month", 6)
          : true) &&
        (_filter.posted === "never" ? !chat.message : true)
    );
    setChats(filtered);
  };

  //////////// NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;

    contents[pos].focus();
  };
  const navResults = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let container = document.getElementById("chatList");
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //navigate to block
  const getInfo = (id) => {
    navigate("/conversation", { state: { id } });
  };

  ////////////////// RENDER GUI //////////////////

  const mappedResults = chats.map((chat, index) => (
    <div
      role="figure"
      aria-label={chat.topicName}
      key={index}
      className="card-long hover"
      onClick={() => {
        getInfo(chat._id);
      }}
      onKeyDown={(e) => navResults(e)}
      tabIndex={0}
    >
      <div
        role="img"
        aria-label={chat.topicName}
        style={{
          backgroundImage: `url(${config.content.server + chat.avatar})`,
          width: "90px",
          height: "90px",
          filter: `${chat.active ? "none" : "grayscale(0.9)"}`,
          opacity: `${chat.active ? 1 : 0.7}`,
        }}
        className="thumb-block auto-margin-narrow"
      ></div>

      <div className={chat.active ? "block-info" : "block-info font-soft"}>
        <div
          role="link"
          className="block-title"
          onKeyDown={(e) => e.key === "Enter" && getInfo(chat._id)}
          tabIndex={0}
        >
          {chat.topicName}
        </div>
        <div style={{ fontSize: "0.8em", marginTop: "0.2em" }}>
          {naturalDate(chat.timestamp, language.locale, language.labels.date)}
        </div>
        {!chat.active && (
          <div
            className="alert"
            style={{ marginTop: "0.25em", fontWeight: 500 }}
          >
            {language.labels.app.closed}
          </div>
        )}
        {chat.message ? (
          <div
            className="handle"
            style={{ marginTop: "0.5em", opacity: `${chat.active ? 1 : 0.7}` }}
          >
            {chat.sender_uname ? chat.sender_uname : language.labels.app.admin}
          </div>
        ) : (
          <div
            className="quote-text"
            style={{
              fontSize: ".9em",
              marginTop: "0.5em",
              opacity: `${chat.active ? 1 : 0.5}`,
            }}
          >
            {language.labels.chat.empty}
          </div>
        )}
        {chat.message && (
          <div
            style={{
              fontSize: ".9em",
              marginTop: "0em",
              opacity: `${chat.active ? 1 : 0.5}`,
            }}
            className="clamped two quote-text"
          >
            {chat.tag === "message" || !chat.tag
              ? chat.message
              : language.labels.chat[chat.tag][chat.message]}
          </div>
        )}
      </div>
    </div>
  ));
  if (auth.level > 2) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                community.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + community.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {page === "new"
                  ? language.labels.chat.add.one
                  : language.labels.navigation.chats}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {community.title}
              </div>
            </div>
          </div>
        </div>

        {/* add conversation */}
        {page === "chats" && (
          <div className="page-section">
            <div
              style={{
                display: "flex",
                gap: "0.5em",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <button className="button-glyph" onClick={() => setPage("new")}>
                +
              </button>
              <div>
                <span className="link" onClick={() => setPage("new")}>
                  {language.labels.chat.add.one}
                </span>
              </div>
            </div>

            {/* search control */}
            {allChats.length > 0 ? (
              <div
                style={{
                  marginTop: "1.5em",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.25em",
                  maxWidth: "52em",
                }}
              >
                <div>
                  <label className="text-label">
                    {language.labels.communities.filter_chats}
                  </label>
                  <Search
                    search_ref={search_ref}
                    filter={toggleFilter}
                    onChange={searchChats}
                    placeholder={language.labels.chat.search_filter}
                  />

                  {showFilter && (
                    <>
                      <label
                        className="text-label"
                        style={{
                          display: "block",
                          fontSize: "0.8em",
                          marginTop: "0.5em",
                        }}
                      >
                        {language.labels.app.filter}
                      </label>
                      <div
                        className="filter"
                        style={{
                          display: "flex",
                          gap: "0.5em",
                          margin: "0.25em 0",
                        }}
                      >
                        <button
                          className={status === "active" ? "on" : "off"}
                          value="active"
                          onClick={(e) => filterStatus(e.target)}
                        >
                          {language.labels.chat.filters.active}
                        </button>
                        <button
                          className={status === "closed" ? "on" : "off"}
                          value="closed"
                          onClick={(e) => filterStatus(e.target)}
                        >
                          {language.labels.chat.filters.closed}
                        </button>
                      </div>

                      <div
                        className="filter"
                        style={{
                          display: "flex",
                          gap: "0.5em",
                          margin: "0.5em 0",
                        }}
                      >
                        <button
                          className={posted === "week" ? "on" : "off"}
                          value="week"
                          onClick={(e) => filterPosted(e.target)}
                        >
                          {language.labels.chat.posts.week}
                        </button>
                        <button
                          className={posted === "month" ? "on" : "off"}
                          value="month"
                          onClick={(e) => filterPosted(e.target)}
                        >
                          {language.labels.chat.posts.month}
                        </button>
                        <button
                          className={posted === "semester" ? "on" : "off"}
                          value="semester"
                          onClick={(e) => filterPosted(e.target)}
                        >
                          {language.labels.chat.posts.semester}
                        </button>
                        <button
                          className={posted === "never" ? "on" : "off"}
                          value="never"
                          onClick={(e) => filterPosted(e.target)}
                        >
                          {language.labels.app.never}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "1em" }}>
                <div>{language.labels.chat.none}</div>
                <button
                  className="button"
                  style={{ marginTop: "2em" }}
                  onClick={() => setPage("new")}
                >
                  {language.labels.chat.add.now}
                </button>
              </div>
            )}

            {allChats.length > 0 && (
              <div style={{ marginTop: "1em", maxWidth: "66rem" }}>
                {status === "all" && term.trim() === "" ? (
                  <div>
                    {language.labels.chat.total.replace(
                      /{n}/g,
                      mappedResults.length
                    )}
                  </div>
                ) : (
                  <div>
                    {language.labels.chat.total_match.replace(
                      /{n}/g,
                      mappedResults.length
                    )}
                  </div>
                )}
                <div id="chatList">{mappedResults}</div>
              </div>
            )}
          </div>
        )}

        {page === "new" && (
          // create new chat
          <ChatCreator
            props={{
              setPage,
              allChats,
              setAllChats,
              setChats,
              setStatus,
              setPosted,
              setTerm,
            }}
            language={language}
            signout={signout}
          />
        )}
      </main>
    );
  } else {
    return <></>;
  }
};

export default Chats;
