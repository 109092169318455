import { React, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import Footer from "../layout/Footer";
import PubBar from "../widgets/public/Bar";

const Suspended = ({ language, checkPolicies }) => {
  const cookies = new Cookies();

  const def_focus = useRef();

  useEffect(() => {
    cookies.remove("yongo");
    localStorage.clear();
  }, []);

  return (
    <>
      <PubBar language={language} />
      <main className="login">
        <h1 className="heading">{language.labels.account.suspended}</h1>

        <button
          className="button"
          ref={def_focus}
          style={{ margin: "2em 0" }}
          onClick={() => window.location.reload()}
        >
          {language.labels.app.ok}
        </button>

        <Footer
          language={language}
          checkPolicies={checkPolicies}
          ref={def_focus}
        />
      </main>
    </>
  );
};

export default Suspended;
