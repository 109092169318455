import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { PubBar } from "../widgets";
import axiosCall from "../../lib/axios";

const AccountDelete = ({ language, signout }) => {
  ///////////////// INITIALIZE /////////////////
  const auth = useSelector((state) => state.authentication.value);
  const navigate = useNavigate();

  const pwd_field = useRef();
  const [err, setErr] = useState("");

  ///////////////// FUNCTION /////////////////
  const removeAccount = async () => {
    console.log(pwd_field.current.value);
    if (!pwd_field.current.value) {
      setErr(language.labels.error.password);
      return;
    }

    setErr("");
    let result = await axiosCall("account/delete", {
      password: pwd_field.current.value,
    });
    if (result.success) {
      console.log(result);
      if (result.status === 200) {
        signout();
      } else if (result.data.error === "owner") {
        setErr(language.labels.account.delete.owner_error);
      } else if (result.data.error === "password") {
        setErr(language.labels.error.password);
      } else {
        signout();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      removeAccount();
    } else {
      // unknown error
      setErr(language.labels.error.unknown);
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <>
      {!auth.account_type && <PubBar language={language} tabIndex={0} />}
      <main
        className={auth.account_type ? "main-page" : "public"}
        tabIndex={0}
        style={{ paddingBottom: "2em" }}
      >
        <div
          className={auth.account_type ? "page-section" : "public-section"}
          style={{ marginTop: `${auth.account_type ? "0" : "1em"}` }}
        >
          {/* heading */}
          <div
            className={auth.account_type ? "mobile-justify" : "web-justify"}
            style={{
              display: "flex",
              fontWeight: 500,
              gap: "0.5em",
              alignItems: "center",
            }}
          >
            <div className="glyphs font-yongo" style={{ fontSize: "2em" }}>
              T
            </div>
            <div style={{ fontSize: "1.4em" }}>
              {language.labels.account.delete.account}
            </div>
          </div>

          <div
            className={auth.account_type ? "mobile-center" : "web-center"}
            style={{ marginTop: "2em" }}
          >
            {/* warning */}
            <div
              className="alert"
              style={{ fontWeight: 500, fontSize: "1.1em" }}
            >
              {language.labels.account.delete.warning_heading}
            </div>
            <div style={{ marginTop: "0.5em" }}>
              {language.labels.account.delete.warning_text}
            </div>

            {auth.account_type ? (
              // owner warning
              <div style={{ marginTop: "0.5em" }}>
                <div>{language.labels.account.delete.owner}</div>
                <div
                  className="alert"
                  style={{
                    marginTop: "1.5em",
                    fontWeight: 500,
                    fontSize: "1.1em",
                  }}
                >
                  {language.labels.account.delete.subscription_heading}
                </div>

                {/* subscription warning */}
                <div style={{ marginTop: "0.5em" }}>
                  {language.labels.account.delete.subscription_text}
                </div>

                <input
                  style={{ marginTop: "2em" }}
                  ref={pwd_field}
                  type={"password"}
                  className="input"
                  placeholder={language.labels.authentication.password_enter}
                  aria-placeholder={
                    language.labels.authentication.password_enter
                  }
                  autoComplete="one-time-code"
                />

                {err && (
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "0.5rem",
                    }}
                    className="errtext"
                    role="alert"
                  >
                    {err}
                  </div>
                )}

                <div>
                  <button
                    className="button"
                    style={{ margin: "1em 0" }}
                    onClick={removeAccount}
                  >
                    {language.labels.account.delete.account}
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "1em" }}>
                <div>{language.labels.account.delete.public}</div>
                <button
                  className="button"
                  style={{ margin: "2em 0" }}
                  onClick={() => navigate("/login")}
                >
                  {language.labels.authentication.signin.user}
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default AccountDelete;
