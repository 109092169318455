import React, { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import Modal from "../../modal/Modal";
import config from "../../../config";
import refreshToken from "../../../lib/refreshToken";
import { formatBytes } from "../../../lib/common";

const AddAnswer = ({
  thread,
  language,
  signout,
  setAddAnswer,
  onChange,
  getThread,
}) => {
  ////////////// INITIALIZE //////////////////
  const [post, setPost] = useState("");

  const _imageFiles = useRef();
  const [imageFiles, setImageFiles] = useState([]);
  const [imageString, setImageString] = useState("");

  const _filesSize = useRef();
  const [filesSize, setFilesSize] = useState(0);

  const [errPost, setErrPost] = useState(false);
  const [errImages, setErrImages] = useState(null);
  const [errImage, setErrImage] = useState(false);

  const [uploadModal, setUploadModal] = useState(false);
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    _imageFiles.current = [];
    _filesSize.current = 0;
    document.getElementById("post_field").scrollIntoView();
  }, []);

  ////////////// FORM //////////////////
  const submitKnowledge = () => {
    clearErrors();

    //check post
    if (post.trim() === "") {
      setErrPost(true);
      document.getElementById("post_field").focus();
      return;
    }

    //gather data
    const formdata = new FormData();
    formdata.append("kid", thread._id);
    formdata.append("post", post.trim());
    if (imageFiles.length > 0) {
      imageFiles.forEach((file) => {
        formdata.append("images", file);
        console.log("file", file);
      });
    }

    //call API
    const configurationObject = {
      url: `${config.server.api}/knowledge/answer/hub/${localStorage.getItem(
        "client_id"
      )}`,
      method: "POST",
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      credentials: "include",
    };

    setUploadModal(true);
    setProcessing(true);

    axios(configurationObject)
      .then(async (response) => {
        if (response.status === 202) {
          let r = await refreshToken();
          if (r) {
            //token refreshed try again
            submitKnowledge();
          } else {
            //refresh token expired
            signout();
          }
        } else {
          //success
          onChange();
          setProcessing(false);
        }
      })
      .catch((error) => {
        //unknown error
        signout();
      });
  };

  const clearErrors = () => {
    setErrPost(false);
    setErrImages(null);
    setErrImage(false);
  };

  const onSuccess = () => {
    getThread();
    setUploadModal(false);
    setProcessing(false);
    setAddAnswer(false);
  };

  ////////////// FILES //////////////////

  const addImage = async (file, hexcode, n, N) => {
    if (_filesSize.current + file.size > config.max_attachment) {
      const size = formatBytes(config.max_attachment);
      if (_filesSize.current > 0) {
        setErrImages(language.labels.error.exceeded.replace(/{size}/g, size));
      } else {
        setErrImages(
          language.labels.error.exceeded_one.replace(/{size}/g, size)
        );
      }
      return n / N;
    }

    if (!config.file.imageCodes.includes(hexcode)) {
      setErrImages(language.labels.error.image);
      return n / N;
    }

    _filesSize.current = _filesSize.current + file.size;
    _imageFiles.current.push(file);
    return n / N;
  };

  const readImageFiles = async (event) => {
    clearErrors();
    var eventSize = 0;
    if (event.target) {
      let n = 0;
      for (let file of event.target.files) {
        await new Promise((resolve, reject) => {
          n++;
          const N = event.target.files.length;

          const fileReader = new FileReader();
          fileReader.onloadend = async function (event) {
            const arr = new Uint8Array(event.target.result).subarray(0, 3);
            let hexcode = "";
            for (let index = 0; index < 3; index++) {
              if (index > 0) hexcode += "-";
              hexcode += arr[index].toString(16);
            }
            const res = await addImage(file, hexcode, n, N);
            if (res === 1) {
              setFilesSize(_filesSize.current);
              setImageFiles(_imageFiles.current);
              setImageString(JSON.stringify(_imageFiles.current));
              event.target.value = null;
            }
          };
          fileReader.readAsArrayBuffer(file);
          resolve();
        });
      }
    }
  };

  const removeFile = (index) => {
    clearErrors();
    setFilesSize(filesSize - imageFiles[index].size);
    let cpy = imageFiles.filter((file, fileIndex) => {
      return fileIndex !== index;
    });
    setImageFiles(cpy);
  };

  ////////////// RENDER GUI //////////////////

  const attachments = imageFiles.map((file, index) => (
    <div
      key={index}
      className="file-container"
      onClick={() => removeFile(index)}
      role="img"
      aria-label={file.name}
    >
      <div
        role="button"
        className="close glyphs"
        tabIndex={0}
        onKeyDown={(e) =>
          (e.key === "Enter" || e.key === " ") && removeFile(index)
        }
        aria-label={language.labels.image.remove}
      >
        x
      </div>
      <img
        className="message-uploaded-image"
        src={URL.createObjectURL(file)}
        aria-hidden="true"
      />
    </div>
  ));

  return (
    <>
      <div className="line" style={{ marginTop: "2em" }}></div>

      {/* Post */}
      <div style={{ maxWidth: "44rem", marginBottom: "1em" }}>
        <label
          htmlFor="post_field"
          style={{
            display: "block",
            margin: "1em 0.25em 0 0.25em",
            fontWeight: "500",
            fontSize: "1.1em",
          }}
        >
          {language.labels.knowledge.your.answer}
        </label>
        <TextareaAutosize
          autoFocus={true}
          id="post_field"
          value={post}
          onChange={(e) => {
            setPost(e.target.value);
          }}
          placeholder={language.labels.answer.enter}
          aria-placeholder={language.labels.answer.enter}
          minRows="4"
          style={{ width: "100%", maxWidth: "44rem" }}
          maxLength={config.string.article}
        />
        {errPost && (
          <div className="errtext" role="alert">
            {language.labels.knowledge.your.answer}
          </div>
        )}
      </div>

      {/* Images */}
      <label style={{ display: "block", margin: "0 0.25em 0.25em 0.25em" }}>
        {language.labels.answer.images}
      </label>
      <label
        role="button"
        style={{ padding: "0 0.25em" }}
        tabIndex="0"
        htmlFor="file-input"
        className="link"
        onKeyDown={(e) =>
          (e.key === "Enter" || e.key === " ") &&
          document.getElementById("file-input").click()
        }
      >
        {language.labels.app.images_button}
      </label>
      <input
        id="file-input"
        key={imageString}
        style={{ display: "none" }}
        onChange={(e) => readImageFiles(e)}
        type="file"
        accept={config.file.imageTypes.toString()}
        multiple
      />
      {attachments.length > 0 && (
        <div
          className="message-file-view"
          aria-label={language.labels.aria.selected_images}
        >
          {attachments}
          <div style={{ margin: "0.25em 0.25em -0.5em 0.25em" }}>
            {formatBytes(filesSize, 1)}
          </div>
        </div>
      )}
      {errImages && (
        <div
          style={{ fontWeight: 600, marginTop: "0.5rem" }}
          className="errtext"
          role="alert"
        >
          {errImages}
        </div>
      )}
      {errImage && (
        <div
          style={{ fontWeight: 600, marginTop: "0.5rem" }}
          className="errtext"
          role="alert"
        >
          {language.labels.error.image}
        </div>
      )}

      {/* Submit/Back */}
      <div style={{ display: "flex", gap: "0.25em", margin: "2em 0" }}>
        <button
          className="button-secondary"
          onClick={() => setAddAnswer(false)}
        >
          {language.labels.app.cancel}
        </button>
        <button className="button" onClick={() => submitKnowledge()}>
          {language.labels.app.add}
        </button>
      </div>

      <Modal className="modal-dynamic" show={uploadModal}>
        {processing ? (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">
              {language.labels.content.process.answer}
            </h2>
            <div
              className="dotdotdot"
              style={{ padding: "0.5rem", fontSize: "1.1em" }}
            >
              {language.labels.content.processing.answer}
            </div>
          </div>
        ) : (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.app.done}</h2>
            <div style={{ padding: "0.5rem", fontSize: "1.1em" }}>
              {language.labels.content.done.answer}
            </div>
            <button
              className="button"
              style={{ margin: "1em 0.25em" }}
              onClick={() => onSuccess()}
            >
              {language.labels.app.ok}
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddAnswer;
