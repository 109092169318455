import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CsvDownloader from "react-csv-downloader";

import axiosCall from "../../lib/axios";
import config from "../../config";
import { Search } from "../widgets";

const Directory = ({ language, signout, main_focus }) => {
  ////////////// INITIALIZE //////////////////
  const navigate = useNavigate();
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);
  const userData = useSelector((state) => state.basic.value);

  const search_ref = useRef();

  const [downloadData, setDownloadData] = useState([]);
  const [communityMembers, setCommunityMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [name, setName] = useState("");

  let headerRow = [
    { id: "username", displayName: language.labels.members.download.username },
    { id: "name", displayName: language.labels.account.name },
    { id: "email", displayName: language.labels.members.download.email },
    { id: "role", displayName: language.labels.members.download.role },
    { id: "account", displayName: language.labels.account.heading },
  ];
  if (community.type === "institution")
    headerRow.push({ id: "status", displayName: language.labels.app.status });

  useEffect(() => {
    getMemberList();
  }, []);

  const getMemberList = async () => {
    let data = { lang: language.lang, type: community.type };
    if (auth.admin) data.cid = community.id;
    let result = await axiosCall("community/memberlist", data);
    console.log(result.data);
    if (result.success) {
      setDownloadData(result.data.list);
      setCommunityMembers(result.data.members);
      setAllMembers(result.data.members);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getMemberList();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// FILTER SEARCH ///////////
  const searchMembers = () => {
    if (search_ref.current?.value) {
      filterMembers(search_ref.current.value);
    } else {
      filterMembers("");
    }
  };

  const filterMembers = async (val) => {
    let filter = { name: name };
    setName(val);
    filter.name = val.trim();
    let members = [...allMembers];
    console.log(members);
    let people = members.filter(
      (member) =>
        member.username.toLowerCase().includes(filter.name.toLowerCase()) ||
        (member.email_alias &&
          member.email_alias.toLowerCase().includes(filter.name.toLowerCase()))
    );
    setCommunityMembers(people);
  };

  ////////////// RENDER GUI //////////////////

  let community_members = communityMembers.map((data, i) => (
    <div
      className="thumb-group hover"
      key={i}
      role="figure"
      aria-label={data.username}
      // onKeyDown={(e) => keyAction(e, i)}
      onClick={(e) => {
        // handleEdit(i);
      }}
      tabIndex="0"
    >
      <div className="thumb-group-content">
        <img
          className="profile-image"
          src={config.content.server + data.avatar}
          alt={data.username}
        />

        <div
          className="handle"
          style={{ width: "100%", padding: "0 0.5em", minHeight: "1em" }}
        >
          {data.email_alias}
        </div>

        <div className="name" style={{ width: "100%", padding: "0.5em" }}>
          {data.uid === userData.uid ? (
            <>{data.username + " (" + language.labels.app.me + ")"}</>
          ) : (
            <>{data.username}</>
          )}
        </div>

        <div className="handle2" style={{ fontSize: "1em" }}>
          {language.labels.roles[data.role]}
        </div>
      </div>
    </div>
  ));

  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <div className="page-section" style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            role="img"
            aria-label={language.labels.aria.logo.replace(
              /{org}/g,
              community.title
            )}
            className="menu-crest"
            style={{
              height: "3em",
              width: "3em",
              backgroundImage: `url("${
                config.content.server + community.crest
              }")`,
            }}
          ></div>
          <div style={{ width: "0.5em" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 2.5em)",
            }}
          >
            <h1 className="heading" style={{ width: "100%", padding: "0" }}>
              {language.labels.communities.directory}
            </h1>
            <div className="handle2" style={{ width: "100%" }}>
              {community.title}
            </div>
          </div>
        </div>
      </div>

      <div className="page-section" style={{ maxWidth: "66rem" }}>
        {auth.level > 2 && (
          <CsvDownloader
            filename={language.labels.members.download.file.replace(
              /{org}/g,
              community.uname
            )}
            columns={headerRow}
            datas={downloadData}
          >
            <button
              className="button"
              style={{ marginTop: "1em", fontSize: "0.9em" }}
            >
              {language.labels.members.download.list}
            </button>
          </CsvDownloader>
        )}

        <div
          className="text-label"
          style={{ marginTop: `${auth.level > 2 ? "2em" : "0"}` }}
        >
          {language.labels.members.filter}
        </div>
        <Search
          search_ref={search_ref}
          onChange={searchMembers}
          placeholder={language.labels.members.filter_placeholder}
        />
      </div>

      <div className="page-section">
        {name.trim() === "" ? (
          <div>
            {language.labels.members.total_count.replace(
              /{n}/g,
              communityMembers.length
            )}
          </div>
        ) : (
          <div>
            {language.labels.communities.staff_filtered.replace(
              /{n}/g,
              communityMembers.length
            )}
          </div>
        )}
      </div>

      <div className="page-section">
        <section
          id="thumb_wall"
          className="thumb-group-container"
          aria-label={language.labels.navigation.community_managers}
        >
          {communityMembers.length > 0 ? (
            community_members
          ) : (
            <i>{language.labels.communities.staff_filtered_none}</i>
          )}
        </section>
      </div>
    </main>
  );
};

export default Directory;
