import screen0 from "../../../media/screen0.jpg";
import screen1 from "../../../media/screen1.jpg";
import screen2 from "../../../media/screen2.jpg";

const PubPreview = ({ language, style }) => {
  return (
    <div className="preview-screens" style={style}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "0.5em",
          fontSize: "1.6em",
        }}
      >
        {language.labels.public.available}
      </div>
      <span>
        <img src={screen0} />
        <img src={screen1} />
        <img src={screen2} />
      </span>
      <div
        style={{
          marginTop: "1em",
          fontSize: "0.8em",
          textAlign: "center",
        }}
      >
        {language.labels.public.limited}
      </div>
    </div>
  );
};

export default PubPreview;
