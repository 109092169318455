import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { VideoEditor } from "../widgets/";
import axiosCall from "../../lib/axios";
import config from "../../config";

const Video = ({ language, main_focus, signout }) => {
  //////////// INITIALIZE ///////////
  const community = useSelector((state) => state.context.value);

  const { state } = useLocation();
  const navigate = useNavigate();
  const [currentVideo, setCurrentVideo] = useState({});

  useEffect(() => {
    checkVideo();
  }, []);

  const checkVideo = async () => {
    if (state.params && state.params._id) {
      let result = await axiosCall("video/check", {
        vid: state.params._id,
      });
      if (result.success) {
        let videoObj = { ...state.params };
        videoObj.bite_image = result.data.thumb;
        if (result.data.description) {
          // add or change description
          setCurrentVideo({
            ...videoObj,
            description: result.data.description,
          });
        } else {
          // remove description
          delete videoObj["description"];
          setCurrentVideo(videoObj);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        checkVideo();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } else {
      navigate("/videos");
    }
  };

  ////////////// FUNCTIONS //////////////////

  const closeVideo = () => {
    navigate("/videos");
  };

  const changeVideoDesc = (desc) => {
    setCurrentVideo({ ...currentVideo, description: desc });
  };

  const navBite = () => {
    navigate("/bite", {
      state: { bid: currentVideo.block, video: currentVideo },
    });
  };

  ////////////// RENDER GUI //////////////////
  if (currentVideo._id) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        {/* heading */}
        <div className="page-section">
          <h1 className="heading">{language.labels.videos.video}</h1>
        </div>

        {/* block */}
        <div className="page-section">
          <h2 className="heading2" style={{ marginBottom: "0.25em" }}>
            {language.labels.videos.bite}
          </h2>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={currentVideo.bite_name}
              className="menu-crest"
              style={{
                width: "3em",
                height: "3em",
                backgroundImage: `url("${
                  config.content.server + currentVideo.bite_image
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <div
                role="link"
                className="link clamped one"
                style={{
                  fontWeight: 500,
                  fontSize: "1.2em",
                  margin: "0.25em 0",
                }}
                onClick={navBite}
                onKeyUpCapture={(e) => e.key === "Enter" && navBite()}
                tabIndex={0}
              >
                {currentVideo.bite_name}
              </div>
              <div className="handle2" style={{ width: "100%" }}>
                {community.title}
              </div>
            </div>
          </div>
        </div>

        {/* video */}
        <div className="page-section">
          <VideoEditor
            style={{ maxWidth: "44rem", paddingBottom: "2em" }}
            language={language}
            currentVideo={currentVideo}
            onDelete={closeVideo}
            onModify={changeVideoDesc}
            onClose={closeVideo}
            signout={signout}
          ></VideoEditor>
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};

export default Video;
