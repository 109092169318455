import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const Redirect = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const auth = useSelector((state) => state.authentication.value);

  const [params, setParams] = useState("");

  //add to list of valid redirection pages
  const pageList = {
    account: "common",
    communityhub: "hub",
  };

  useEffect(() => {
    setParams(new URLSearchParams(window.location.search));
  }, []);

  useEffect(() => {
    if (params) {
      let page = "";
      let terms = [];
      for (const p of params) {
        if (p[0] === "page") {
          page = p[1];
        } else {
          terms.push(...p);
        }
      }
      if (page && pageList[page]) {
        chkPage(page, terms);
      } else {
        //no redirection
        navigate("/");
      }
    }
  }, [params]);

  const chkPage = (page, terms) => {
    //clear existing redirection cookie
    cookies.remove("redirection");

    let cookie = { page };

    //get state
    let state = {};
    if (terms) {
      terms.forEach(function (v, i, a) {
        // if (i % 2) states.push({ [a[i - 1]]: v });
        if (i % 2) state[a[i - 1]] = v;
      });
    }
    if (Object.keys(state).length > 0) cookie.state = state;

    if (
      auth.account_type &&
      auth.account_type !== "suspended" &&
      auth.agree_terms === "ok"
    ) {
      if (Object.keys(state).length > 0) {
        navigate("/" + page, { state });
      } else {
        navigate("/" + page);
      }
    } else if (!auth.account_type) {
      //login
      if (pageList[page] !== "common") {
        cookies.set("client", true, { path: "/" });
      }
      cookies.set("redirection", cookie, { path: "/" });
      navigate("/login");
    }
  };

  return <></>;
};

export default Redirect;
