import { React, useState, useEffect } from "react";
import config from "../../config";

const enterprize = require("../../media/enterprize.png");
const free = require("../../media/free.png");
const institution = require("../../media/institution.png");
const community = require("../../media/community.png");

const PricingPlans = ({ language, styles }) => {
  ////////////// INITIALIZE /////////////////
  const currency = config.currencies.default; //NEXT RELEASE set to user's country
  const products = language.labels.products;
  const [plan, setPlan] = useState(config.pricing[0].plan);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [dollars, setDollars] = useState("");
  const [cents, setCents] = useState("");

  //set selection values
  useEffect(() => {
    setPlan(config.pricing[currentIndex].plan);
    if (config.pricing[currentIndex].hasOwnProperty(currency.currency)) {
      setSymbol(currency.symbol);
      let base = Math.floor(config.pricing[currentIndex][currency.currency]);
      let quotient = Math.round(
        100 * (config.pricing[currentIndex][currency.currency] - base)
      );
      let _cents = quotient.toString();
      if (quotient < 10) {
        _cents = ".0" + _cents;
      } else {
        _cents = "." + _cents;
      }
      setDollars(base.toString());
      setCents(_cents);
    } else {
      setSymbol("");
      setDollars(products[config.pricing[currentIndex].plan].price_check || "");
      setCents("");
    }
  }, [currentIndex]);

  ////////////// RENDER GUI /////////////////
  const pricing = config.pricing.map((p, index) => (
    <button
      className={
        `${index === 0 ? "pricing-start" : ""}` +
        `${
          index > 0 && index < config.pricing.length - 1 ? "pricing-middle" : ""
        }` +
        `${index === config.pricing.length - 1 ? "pricing-end" : ""}` +
        `${p.plan === plan ? " on" : ""}`
      }
      onClick={() => setCurrentIndex(index)}
    >
      {language.labels.account.types[p.plan]}
    </button>
  ));

  return (
    <div style={styles}>
      <div className="pricing">{pricing}</div>

      <div className="price-box" style={{ marginTop: "1em" }}>
        {products[plan].headline && (
          <div className="headline">{products[plan].headline}</div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          {symbol && <div className="currency">{symbol}</div>}
          {dollars && <div className="amount">{dollars}</div>}
          {cents && <div className="note">{cents}</div>}
        </div>
        {products[plan].billing && (
          <div className="note">{products[plan].billing}</div>
        )}
      </div>

      <div className="pricing-plan">
        {plan === "free" && <img src={free} />}
        {plan === "community" && <img src={community} />}
        {plan === "institution" && <img src={institution} />}
        {plan === "enterprize" && <img src={enterprize} />}
        <div dangerouslySetInnerHTML={{ __html: products[plan].details }} />
      </div>
    </div>
  );
};

export default PricingPlans;
