import React from "react";
import { useNavigate } from "react-router-dom";

const Welcome = ({ language, main_focus }) => {
  const navigate = useNavigate();

  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <div
        className="page-section"
        style={{
          marginBottom: "2em",
          maxWidth: "44rem",
        }}
      >
        <h1 className="heading">{language.labels.brand.welcome_hub}</h1>
        <div
          className="list-container"
          style={{ fontSize: "1.2rem" }}
          role="complementary"
          aria-label={language.labels.aria.features}
        >
          <div className="sr-only">
            {language.labels.brand.pointers.start_community}
          </div>
          <div className="sr-only">
            {language.labels.brand.pointers.add_blocks}
          </div>
          <div className="sr-only">
            {language.labels.brand.pointers.grow_knowledge}
          </div>
          <ul aria-hidden="true">
            <li>{language.labels.brand.pointers.start_community}</li>
            <li>{language.labels.brand.pointers.add_blocks}</li>
            <li>{language.labels.brand.pointers.grow_knowledge}</li>
          </ul>
        </div>
        {language.labels.app.greeting}
      </div>

      <div
        className="page-section"
        style={{ textAlign: "center", maxWidth: "44rem" }}
      >
        <button
          id="new_link"
          className="button"
          onClick={() => {
            navigate("/newcommunity");
          }}
        >
          {language.labels.navigation.community_start}
        </button>
      </div>
    </main>
  );
};

export default Welcome;
