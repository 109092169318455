import ios from "../../media/ios.svg";
import android from "../../media/android.svg";
import config from "../../config";

const GetApp = ({ os, myclass }) => {
  return (
    <div
      className={myclass || "mobile-justify"}
      style={{ display: "flex", gap: "6px" }}
    >
      {os !== "ios" && (
        // <a href={config.app_download.android} target="_blank">
        <img className="hoverX" src={android} />
        // </a>
      )}
      {os !== "android" && (
        // <a href={config.app_download.android} target="_blank">
        <img className="hoverX" src={ios} />
        // </a>
      )}
    </div>
  );
};

export default GetApp;
