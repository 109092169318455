import React, { useState } from "react";
import { naturalDate } from "../../../lib/date";
import { LessonCreator, LessonViewer } from "../../widgets";

const BlockLessons = ({
  community,
  language,
  bid,
  blockLessons,
  lessonsState,
  setLessonsState,
  getLessons,
  setRefreshLessons,
  signout,
}) => {
  //////////// INITIALIZE ///////////
  const [currentLesson, setCurrentLesson] = useState({});

  //////////// NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let container = document.getElementById("threadResults");
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// LESSONS ///////////
  const onSuccess = () => {
    getLessons(true);
  };

  const onClose = (refresh = false) => {
    if (refresh) {
      getLessons(true);
    } else {
      setLessonsState("list");
    }
  };

  const onCancel = () => {
    setLessonsState("list");
  };

  const displayLesson = (lesson) => {
    setCurrentLesson(blockLessons[lesson]);
    setLessonsState("view");
  };

  const refreshLearning = () => {
    setRefreshLessons(true);
  };

  //////////// RENDER GUI ///////////

  const BlockLessons = blockLessons.map((lesson, idx) => (
    <div
      role="figure"
      key={idx}
      className="card-long hover"
      onClick={() => displayLesson(idx)}
      onKeyDown={(e) => navResults(e)}
      tabIndex={0}
      style={{ width: "100%" }}
    >
      <div
        role="link"
        onKeyDown={(e) => e.key === "Enter" && displayLesson(lesson._id)}
        tabIndex={0}
        style={{ display: "flex", gap: "1em", width: "100%" }}
      >
        <div
          className="glyphs font-contrast-hard"
          style={{ fontSize: "2.4em", marginTop: "-5px" }}
        >
          l
        </div>
        <div>
          <div
            className="clamped one"
            style={{
              fontWeight: "500",
              fontSize: "1.1em",
              marginBottom: "0.25em",
            }}
          >
            {lesson.title}
          </div>
          <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
            {naturalDate(lesson.created, language.locale, language.labels.date)}
          </div>

          {!lesson.user && (
            <div className="handle" style={{ fontSize: "11px" }}>
              {language.labels.app.expert}
            </div>
          )}

          <div
            style={{ fontSize: ".9em", marginTop: "0.5em" }}
            className={`quote-text${
              lesson.status === "available" && " clamped two"
            }`}
          >
            {lesson.status === "available"
              ? lesson.plan
              : lesson.status === "pending"
              ? language.labels.lesson.pending
              : language.labels.app.images_processing}
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div
      role="tabpanel"
      style={{ paddingBottom: "2em" }}
      aria-label={language.labels.navigation.lessons}
      tabIndex={0}
    >
      {lessonsState === "list" && (
        <div style={{ width: "100%", maxWidth: "60rem" }}>
          <div style={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
            <button
              className="button-glyph"
              onClick={() => {
                setLessonsState("new");
              }}
            >
              +
            </button>
            <div>
              <span
                className="link"
                onClick={() => {
                  setLessonsState("new");
                }}
              >
                {language.labels.lesson.add}
              </span>
            </div>
          </div>
          <div style={{ margin: "1em 0 0.5em 0", display: "flex", gap: "1em" }}>
            <div>
              {blockLessons.length < 1
                ? language.labels.lesson.none_bite
                : `${
                    blockLessons.length > 1
                      ? language.labels.lesson.multiple.replace(
                          /{n}/g,
                          blockLessons.length
                        )
                      : language.labels.lesson.one
                  }`}
            </div>
            <button
              aria-label={language.labels.aria.refresh_lessons}
              className="glyphs success"
              onClick={() => getLessons(true)}
            >
              r
            </button>
          </div>
          <div id="threadResults">{BlockLessons}</div>
        </div>
      )}

      {lessonsState === "new" && (
        <LessonCreator
          bid={bid}
          language={language}
          signout={signout}
          onCancel={onCancel}
          onSuccess={onSuccess}
          cancelLabel={language.labels.app.cancel}
        ></LessonCreator>
      )}

      {lessonsState === "view" && (
        <LessonViewer
          style={{ maxWidth: "1600px", paddingBottom: "2em" }}
          language={language}
          community={community}
          currentLesson={currentLesson}
          setCurrentLesson={setCurrentLesson}
          onClose={onClose}
          onChange={refreshLearning}
          onDelete={onSuccess}
          bite={true}
          signout={signout}
        ></LessonViewer>
      )}
    </div>
  );
};

export default BlockLessons;
