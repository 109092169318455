import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import config from "../../config";
import axiosCall from "../../lib/axios";

const Vid = ({ language, agreeTerms, signout, pageHeight }) => {
  ////////////// INITIALIZE //////////////
  const navigate = useNavigate();
  // const play_canvas = useRef();
  const play_video = useRef();
  // const pause_button = useRef();
  // const track_bar = useRef();
  const watermark = useRef();

  const [video, setVideo] = useState("");
  const [thumb, setThumb] = useState("");
  const [started, setStarted] = useState(false);
  const [paused, setPaused] = useState(true);
  const [style, setStyle] = useState({});
  const [sign, setSign] = useState(false);
  const [community, setCommunity] = useState("");
  const [heightDelta, setHeightDelta] = useState(3);

  useEffect(() => {
    //get url params
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    if (params.get("v")) {
      if (agreeTerms) {
        setHeightDelta(80);
        getSigned(params.get("v"));
      } else {
        getUnSigned(params.get("v"));
        setStyle({ padding: "0", background: "black", color: "white" });
      }
    } else {
      //no video specified
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (started && video) startVideo();
  }, [started]);

  useEffect(() => {
    let already_started = false;
    if (started) {
      already_started = true;
      play_video.current.pause();
      // track_bar.current.style.width = "0";
    }
    if (already_started) {
      setStarted(false);
      setTimeout(() => {
        setStarted(true);
        setPaused(false);
        play_video.current.play();
      }, 50);
    }
  }, [pageHeight]);

  ////////////// GET VIDEO //////////////

  const processVideo = (result) => {
    if (result.url) {
      //successfully retrieved url
      setVideo(result.url);
      setThumb(config.content.cache + result.thumb);
    } else if (result.community) {
      //not a member of the community
      const html = language.labels.videos.private.replace(
        /{uname}/g,
        result.community.uname
      );
      setCommunity(html.replace(/{title}/g, result.community.title));
      if (!agreeTerms) setSign(true);
    } else {
      //unknown error
      navigate("/");
    }
  };

  const getSigned = async (vid) => {
    let result = await axiosCall("video/signature", { vid });
    if (result.success) {
      processVideo(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getSigned(vid);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const getUnSigned = async (vid) => {
    document.getElementById("loader").style.display = "inline";
    const configurationObject = {
      url: `${config.server.api}/auth/video`,
      method: "POST",
      data: { vid },
    };
    let result = await axios(configurationObject);
    document.getElementById("loader").style.display = "none";
    processVideo(result.data);
  };

  ////////////// PLAY VIDEO //////////////

  const startVideo = () => {
    const container = play_video.current.parentNode;
    play_video.current.addEventListener(
      "play",
      function (e) {
        //calculate dimensions
        const aspect = e.target.videoWidth / e.target.videoHeight;
        let view_space = container.clientWidth / container.clientHeight;
        let _ratio = container.clientHeight / e.target.videoHeight;
        let offset = 0;
        if (aspect > view_space)
          _ratio = container.clientWidth / e.target.videoWidth;
        const ratio = Math.min(1, _ratio);
        var cw = e.target.videoWidth * ratio;
        var ch = e.target.videoHeight * ratio;
        offset = Math.floor((container.clientWidth - cw) / 2);
        play_video.current.style.width = cw + "px";
        play_video.current.style.height = ch + "px";
        if (!agreeTerms && offset > 0) {
          play_video.current.style.left = offset + "px";
          watermark.current.style.left = offset + "px";
        } else {
          play_video.current.style.left = "0";
        }
      },
      false
    );
    play_video.current.play();
  };

  ////////////// RENDER GUI //////////////
  return (
    <main className="main-page" style={style}>
      {/* Heading */}
      {agreeTerms && (
        <div className="page-section" style={{ paddingTop: "4px" }}>
          <div style={{ display: "flex" }}>
            <div className="heading-icon">F</div>
            <h1 className="heading">{language.labels.videos.shared}</h1>
          </div>
        </div>
      )}

      {!agreeTerms && !started && (
        <div
          className="hover"
          style={{
            padding: "1em 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => navigate("/")}
          onKeyUpCapture={(e) => e.key === "Enter" && navigate("/   ")}
          tabIndex="0"
          role="link"
        >
          <div className="glyphs" style={{ fontSize: "4em" }}>
            Y
          </div>
          <h1
            style={{
              fontFamily: "Logo",
              fontWeight: 600,
              fontSize: "2.4em",
              marginTop: "-0.2em",
            }}
          >
            {language.labels.brand.name}
          </h1>
        </div>
      )}

      {thumb && !started && (
        <div style={{ textAlign: `${agreeTerms ? "initial" : "center"}` }}>
          <div
            className="page-section"
            style={{
              paddingTop: "0",
              position: "relative",
              display: "inline-block",
            }}
          >
            <div
              className="video-button hover"
              onClick={() => {
                setStarted(true);
              }}
            >
              \
            </div>
            <img
              className="hover"
              src={thumb}
              onClick={() => {
                setStarted(true);
              }}
              style={{ maxWidth: "280px" }}
            ></img>
          </div>
        </div>
      )}

      {/* {!community && ( */}
      <div
        className="page-section"
        style={{
          display: `${agreeTerms ? "" : "flex"}`,
          justifyContent: "center",
          height: Math.max(200, pageHeight - heightDelta),
          padding: `${agreeTerms ? "4px" : "0"}`,
        }}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          {/* watermark */}
          {!agreeTerms && started && (
            <div
              ref={watermark}
              className="hover"
              style={{
                position: "absolute",
                top: "8px",
                left: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
              }}
              onClick={() => navigate("/")}
              onKeyUpCapture={(e) => e.key === "Enter" && navigate("/   ")}
              tabIndex="0"
              role="link"
            >
              <div
                className="glyphs"
                style={{ fontSize: "2.4em", textShadow: "1px 1px 2px black" }}
              >
                Y
              </div>
              <h1
                style={{
                  fontFamily: "Logo",
                  fontWeight: 600,
                  fontSize: "1.4em",
                  marginTop: "-0.2em",
                  textShadow: "1px 1px 2px black",
                }}
              >
                {language.labels.brand.name}
              </h1>
            </div>
          )}

          {video && (
            <video
              ref={play_video}
              style={{
                position: "relative",
                display: `${started ? "" : "none"}`,
                // maxWidth: "100%",
                // height: "auto",
              }}
              controls
              webkit-playsInline={true}
              playsInline={true}
            >
              <source src={video} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
      {/* )} */}

      {sign && !agreeTerms && (
        <div className="page-section">
          <div>{language.labels.videos.anon}</div>
          <button
            className="button"
            style={{ marginTop: "1em" }}
            onClick={() => {
              navigate("/");
            }}
          >
            {language.labels.authentication.go_to_signin}
          </button>
        </div>
      )}
      {community && (
        <div className="page-section">
          <div dangerouslySetInnerHTML={{ __html: community }} />
        </div>
      )}
    </main>
  );
};

export default Vid;
