import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import config from "../../config";
import axios from "axios";
import { localSignIn } from "../../lib/signin";

const Sso = ({ language }) => {
  ////////////// INITIALIZE //////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //display loader
    document.getElementById("loader").style.display = "inline";

    //get url params
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    if (params.get("hash") && params.get("id") && params.get("u")) {
      singleSignIn(params.get("hash"), params.get("id"), params.get("u"));
    } else {
      navigate("/");
    }
  }, []);

  ////////////// SINGLE SIGN IN //////////////
  const singleSignIn = async (hash, id, u) => {
    try {
      const response = await axios({
        url: `${config.server.api}/auth/sso/hub`,
        method: "POST",
        data: { hash, id, u },
        withCredentials: true,
        credentials: "include",
      });
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        localStorage.clear();
        await localSignIn(dispatch, response.data);
        if (response.data.communities) {
          navigate("/communityhub");
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  };

  ////////////// RENDER GUI //////////////

  return (
    <main className="login">
      <div className="login-background"></div>
      <div className="logo-container">
        <span>Y</span>
        <div>{language.labels.brand.name}</div>
      </div>
    </main>
  );
};

export default Sso;
