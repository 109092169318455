import { React, useState, useEffect } from "react";

import { PubBar } from "../widgets";

const Policy = ({ language, checkPolicies, policy }) => {
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");

  useEffect(() => {
    getPolicy();
  }, []);

  const getPolicy = async () => {
    let _policy = "PP";
    if (policy) _policy = policy;
    const _policies = await checkPolicies();
    var i = 0,
      len = _policies.length;
    while (i < len) {
      if (_policies[i].policy === _policy) {
        setTitle(_policies[i].title);
        setHtml(_policies[i].html);
        break;
      }
      i++;
    }
  };

  return (
    <main>
      <PubBar language={language} buttons={true} />

      <div className="public section" style={{ paddingBottom: "2em" }}>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </main>
  );
};

export default Policy;
