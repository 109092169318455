import React, { useState, useRef } from "react";
import axios from "axios";
import Modal from "../../modal/Modal";
import config from "../../../config";
import encryption from "../../../lib/encryption";
import refreshToken from "../../../lib/refreshToken";

const VideoUploader = ({
  bid,
  language,
  signout,
  onCancel,
  onSuccess,
  cancelLabel,
  hintLabel,
}) => {
  ////////////// INITIALIZE //////////////////

  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);
  const [invalid, setInvalid] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const aria_button = useRef();
  const desc_field = useRef();
  const upload_percent = useRef();
  const video_form = useRef();
  const video_preview = useRef();

  ////////////// SELECT FILE //////////////////

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    aria_button.current.classList.add("hovering");
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    aria_button.current.classList.add("hovering");
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const readDroppedFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    aria_button.current.classList.remove("hovering");

    if (e.dataTransfer.items[0]) {
      const droppedFile = e.dataTransfer.items[0].getAsFile();
      readFile(droppedFile);
    }
  };

  const readSearchedFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      readFile(e.target.files[0]);
    }
  };

  const readFile = (chosenFile) => {
    setError(null);
    setInvalid(null);
    var mimeType = /video.*/;
    if (chosenFile.type.match(mimeType)) {
      if (chosenFile.size > config.video.max_filesize) {
        setInvalid(language.labels.videos.error.exceeds);
        setVideo(null);
      } else {
        setVideo(URL.createObjectURL(chosenFile));
        setVideo(chosenFile);
      }
    } else {
      setInvalid(language.labels.videos.error.invalid);
    }
  };

  const resetVideo = (e) => {
    video_form.current.reset();
    if (e.key) aria_button.current.focus();
    setError(null);
    setVideo(null);
    setInvalid(null);
  };

  ////////////// SUBMIT FORM //////////////////

  const submitVideo = (e) => {
    //check that we have a video first
    if (video) {
      e.preventDefault();
      const _video = video_preview.current;
      if (
        video &&
        _video &&
        _video.videoWidth &&
        _video.videoHeight &&
        _video.duration
      ) {
        //check resolution
        const resolution = Math.max(_video.videoWidth, _video.videoHeight);
        if (resolution > config.video.resolution) {
          setError(language.labels.videos.error.big);
          return;
        }

        //check duration - if unknown duration is 'Infinity' or '+Infinity'
        const d = _video.duration.toString();
        if (d.indexOf("Infinity") < 0) {
          if (_video.duration < config.video.min_duration) {
            setError(language.labels.videos.error.short);
            return;
          }
          if (_video.duration > config.video.max_duration) {
            setError(language.labels.videos.error.long);
            return;
          }
        }

        //check out ok so submit data
        submitVideoForm();
      } else {
        setError(language.labels.error.file);
        return;
      }
    } else {
      setError(language.labels.videos.error.select);
    }
  };

  const submitVideoForm = () => {
    //gather data
    const desc = desc_field.current.value.trim();
    const formdata = new FormData();
    formdata.append("videoFile", video);
    if (desc !== "") formdata.append("description", desc);
    formdata.append("bid", bid);

    //initialize
    const jwt = encryption.decrypt(localStorage.tag);
    let percent = 0;
    const configurationObject = {
      url: `${config.server.video}/load/hub/${localStorage.getItem(
        "client_id"
      )}`,
      method: "POST",
      data: formdata,
      headers: { "Content-Type": "multipart/form-data", "x-access-token": jwt },
      withCredentials: true,
      credentials: "include",
      onUploadProgress: function (event) {
        percent = Math.round((100 * event.loaded) / event.total);
        upload_percent.current.innerText = percent + "%";
        if (event.loaded === event.total) changeProgress(0);
      },
    };

    //try to post video
    setUploadModal(true);
    setUploading(true);
    setProcessing(false);
    setCompleted(false);
    axios(configurationObject)
      .then(async (response) => {
        console.log(response);
        if (response.status === 202) {
          let r = await refreshToken();
          if (r) {
            //token refreshed try again
            submitVideoForm();
          } else {
            //refresh token expired
            signout();
          }
        } else {
          //success
          video_form.current.reset();
          changeProgress(1);
        }
      })
      .catch((error) => {
        //unknown error
        signout();
      });
  };

  function changeProgress(n) {
    setUploading(false);
    if (n > 0) {
      setProcessing(false);
      setCompleted(true);
    } else {
      setProcessing(true);
      setCompleted(false);
    }
  }

  ////////////// KEYBOARD ACTIONS //////////////////
  //open file selector
  const pickFile = (e) => {
    //stopPropagation not working so check that settings is pressed
    if (
      e.target === aria_button.current &&
      (e.key === "Enter" || e.key === " ")
    )
      document.getElementById("file-input").click();
  };

  ////////////// RENDER GUI //////////////////

  return (
    <>
      <form
        ref={video_form}
        aria-label={language.labels.aria.form.video}
        style={{ maxWidth: "44rem" }}
        onSubmit={(e) => submitVideo(e)}
      >
        <div>{language.labels.videos.tip}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "512px",
            marginTop: "0.5em",
          }}
        >
          <div style={{ marginTop: "5px", opacity: `${video ? "0" : "1"}` }}>
            <input
              id="file-input"
              className="sr-only"
              aria-label={language.labels.aria.image.file_button}
              onClick={() => video_form.current.reset()}
              onChange={(e) => readSearchedFile(e)}
              type="file"
              accept="video/*"
            />
            <div
              role="button"
              className={`crest-edit-container ${invalid && "invalid"}`}
              ref={aria_button}
              style={{
                display: "flex",
                width: "288px",
                height: "512px",
                justifyContent: "center",
              }}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDragEnter={onDragEnter}
              onDrop={(e) => readDroppedFile(e)}
              onKeyDown={(e) => pickFile(e)}
              tabIndex="0"
            >
              <label
                aria-hidden="true"
                style={{ cursor: "pointer", textAlign: "center" }}
                htmlFor="file-input"
              >
                {invalid ? invalid : language.labels.image.drag_drop}
              </label>
            </div>
          </div>
          {/* preview */}
          {video && (
            <div
              style={{
                background: "black",
                height: "512px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <video
                ref={video_preview}
                style={{ maxWidth: "288px", height: "auto" }}
                src={URL.createObjectURL(video)}
                controls
                webkit-playsInline
                playsInline
              ></video>
            </div>
          )}
        </div>

        {/* reset */}
        {video && (
          <div>
            <span
              role="button"
              className="subtext link"
              onClick={(e) => resetVideo(e)}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && resetVideo(e)
              }
              tabIndex={0}
            >
              {language.labels.videos.reset}
            </span>
          </div>
        )}
      </form>

      <div style={{ maxWidth: "44rem" }}>
        {/* optional description */}
        <textarea
          ref={desc_field}
          placeholder={language.labels.videos.description}
          aria-placeholder={language.labels.videos.description}
          rows="2"
          style={{ width: "100%", margin: "1em 0" }}
          maxLength={config.string.bio}
        />

        {/* submit button */}
        {error && (
          <div style={{ fontWeight: 600 }} className="errtext" role="alert">
            {error}
          </div>
        )}
        <div
          style={{ display: "flex", gap: "0.25em", margin: "0.5em 0 2em 0" }}
        >
          <button className="button-secondary" onClick={() => onCancel()}>
            {cancelLabel}
          </button>
          <button className="button" onClick={(e) => submitVideo(e)}>
            {language.labels.app.upload}
          </button>
        </div>
      </div>

      <Modal className="modal-dynamic" show={uploadModal}>
        {uploading && (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.videos.uploading.wait}</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
                padding: "0.5rem",
                fontSize: "1.1em",
              }}
            >
              <div>{language.labels.videos.uploading.progress}</div>
              <div ref={upload_percent} style={{ fontWeight: "500" }}></div>
            </div>
          </div>
        )}
        {processing && (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">
              {language.labels.videos.uploading.process}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
                padding: "0.5rem",
                fontSize: "1.1em",
              }}
            >
              <div>{language.labels.videos.uploading.uploaded}</div>
              <div className="glyphs success">*</div>
            </div>
            <div
              className="dotdotdot"
              style={{ padding: "0.5rem", fontSize: "1.1em" }}
            >
              {language.labels.videos.uploading.processing}
            </div>
          </div>
        )}
        {completed && (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.app.done}</h2>
            <div style={{ padding: "0.5rem", fontSize: "1.1em" }}>
              {language.labels.videos.uploading.done}
            </div>
            {hintLabel && (
              <div
                style={{ padding: "0.5rem", fontSize: "1.1em" }}
                dangerouslySetInnerHTML={{ __html: hintLabel }}
              />
            )}
            <button
              className="button"
              style={{ margin: "1em 0.25em" }}
              onClick={() => onSuccess()}
            >
              {language.labels.app.ok}
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default VideoUploader;
