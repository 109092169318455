import Cookies from "universal-cookie";

import { login } from "../features/authentication";
import { setBasic } from "../features/basic";
import { setCommunities, unsetCommunities } from "../features/communities";
import { setContext, unsetContext } from "../features/context";
import { setEmails, unsetEmails } from "../features/emails";

import config from "../config";
import encryption from "../lib/encryption";

const getContext = async (id, role, communities) => {
  let community = {};
  for (let i = 0; i < communities.length; i++) {
    if (communities[i].id === id && communities[i].role === role) {
      community = communities[i];
      break;
    }
  }
  return community;
};

const localSignIn = async (dispatch, obj) => {
  const cookies = new Cookies();
  try {
    //set the cookie
    cookies.set("yongo", true, { path: "/" });

    //set tag, client_id and last_update
    localStorage.setItem("last_updated", Date.now());
    const hash = encryption.encrypt(obj.jwt);
    localStorage.setItem("tag", hash);
    localStorage.setItem("client_id", obj.client_id);

    //auth
    let loginState = {
      client: obj.client,
      agree_terms: obj.agree_terms,
      account_type: obj.basic.account_type,
      created: obj.basic.created,
    };
    if (obj.admin) loginState.admin = obj.admin;
    if (obj.community_id && obj.role && obj.communities) {
      loginState.community_id = obj.community_id;
      loginState.role = obj.role;
      loginState.level = config.levels.indexOf(obj.role);

      //context
      const context = await getContext(
        obj.community_id,
        obj.role,
        obj.communities
      );
      if (context.id) {
        localStorage.setItem("context", JSON.stringify(context));
        dispatch(setContext(context));
      } else {
        localStorage.removeItem("context");
        dispatch(unsetContext());
      }
    } else if (obj.communities) {
      if (obj.communities.length === 1) {
        loginState.community_id = obj.communities[0].id;
        loginState.role = obj.communities[0].role;
        loginState.level = config.levels.indexOf(obj.communities[0].role);

        //context
        localStorage.setItem("context", JSON.stringify(obj.communities[0]));
        dispatch(setContext(obj.communities[0]));
      } else {
        localStorage.removeItem("context");
        dispatch(unsetContext());
      }
    } else {
      localStorage.removeItem("context");
      dispatch(unsetContext());
    }
    localStorage.setItem("auth", JSON.stringify(loginState));
    dispatch(login(loginState));

    //basic
    let basicState = {
      uid: obj.basic._id,
      username: obj.basic.username,
      avatar: obj.basic.avatar,
      phone_number: obj.basic.phone_number,
      country: obj.basic.country || "-",
    };
    if (obj.basic.fullName) basicState.fullName = obj.basic.fullName;
    if (obj.basic.bio) basicState.bio = obj.basic.bio;
    localStorage.setItem("basic", JSON.stringify(basicState));
    dispatch(setBasic(basicState));

    //communities
    if (obj.communities) {
      localStorage.setItem("communities", JSON.stringify(obj.communities));
      dispatch(setCommunities(obj.communities));
    } else {
      localStorage.removeItem("communities");
      dispatch(unsetCommunities());
    }

    //emails
    if (obj.emails) {
      localStorage.setItem("emails", JSON.stringify(obj.emails));
      dispatch(setEmails(obj.emails));
    } else {
      localStorage.setItem("emails", "[]");
      dispatch(unsetEmails());
    }
  } catch (error) {
    console.error(`LOGGED ERROR: \n`, error);
  }
};

export { getContext, localSignIn };
