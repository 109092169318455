import { React, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Details, Preview, Image, Success } from "./blockSteps";
import axiosCall from "../../../lib/axios";
import { sessionExpired } from "../../../lib/common";
import config from "../../../config";

const NewBlocks = ({ language, signout, main_focus }) => {
  //initialize
  const navigate = useNavigate();
  const { state } = useLocation();
  const community = useSelector((state) => state.context.value);

  //state props
  const [topic, setTopic] = useState("");
  const [topicId, setTopicId] = useState("");
  const [bitesArr, setBitesArr] = useState([]);
  const [activeBites, setActiveBites] = useState([]);
  const [biteCount, setBiteCount] = useState(0);

  useEffect(() => {
    //check state
    if (
      state?.topic &&
      state?.topicId &&
      state?.bitesArr &&
      state.bitesArr.length > 0
    ) {
      setTopic(state.topic);
      setTopicId(state.topicId);
      setBitesArr(state.bitesArr);
      setActiveBites(state.bitesArr);
      setBiteCount(state.bitesArr.length);
    } else {
      navigate("/pathways");
    }
  }, []);

  //props
  const [currBite, setCurrBite] = useState(0);
  const [description, setDescription] = useState("");
  const [duplicates, setDuplicates] = useState([]);
  const [equivalent, setEquivalent] = useState(false);
  const [vague, setVague] = useState(false);
  const [extraSections, setExtraSections] = useState([]);

  //steps
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState([]);

  /////////// CREATE BLOCK  ////////////////

  const createBlock = async (image) => {
    //deep copy bites and remove entry
    let pending = [...activeBites];
    pending.splice(0, 1);

    // try and submit data
    const data = {
      topic,
      pid: topicId,
      pending,
      title: bitesArr[currBite],
      description,
      thumb: image,
      extras: extraSections,
    };

    let result = await axiosCall("block/create", data);
    if (result.success) {
      setActiveBites(pending);
      skip("animateImage");
    } else if (result.refresh) {
      //token has been refreshed, try again
      createBlock(image);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// STEPS /////////////////
  const next = async (step) => {
    // check we haven't timed out first
    let timedout = await sessionExpired();

    if (timedout) {
      signout();
    } else {
      setNextStep(step);
      setTimeout(() => {
        setCurrentStep(step.step);
        setNextStep([]);
      }, 300);
    }
  };

  const skip = (page) => {
    if (currBite + 1 < biteCount) {
      setCurrBite(currBite + 1);
      setCurrentStep(0);
      setNextStep([]);
      setDescription("");
      setExtraSections([]);
      if (page !== "aboutAnimate") {
        document.getElementById(page).style.animationName = "slideout";
        next({ step: 0, direction: 1 });
      }
    } else {
      document.getElementById(page).style.animationName = "slideout";
      next({ step: 3, direction: 1 });
    }
  };

  const allSteps = [
    <Details
      language={language}
      animation={nextStep}
      next={next}
      skip={skip}
      signout={signout}
      props={{
        currBite,
        biteCount,
        bitesArr,
        topic,
        topicId,
        description,
        setDescription,
        extraSections,
        setExtraSections,
        setVague,
        setEquivalent,
        setDuplicates,
      }}
    />,
    <Preview
      language={language}
      animation={nextStep}
      duplicates={duplicates}
      community={community}
      next={next}
      skip={skip}
      signout={signout}
      props={{ vague, equivalent, topicId, communityPathways: [] }}
    />,
    <Image
      next={next}
      animation={nextStep}
      language={language}
      createBlock={createBlock}
      props={{ bitesArr, currBite, description, extraSections }}
    />,
    <Success
      language={language}
      animation={nextStep}
      topicId={topicId}
      navigate={navigate}
    />,
  ];

  return (
    <>
      <main className="main-page" ref={main_focus} tabIndex="0">
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                community.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + community.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.bites.add}
              </h1>
              <div className="handle2">{community.title}</div>
            </div>
          </div>
        </div>

        {/* topic and current bite */}
        <div className="page-section">
          <h2
            className="heading2"
            style={{
              fontWeight: "bold",
              fontSize: "1.1em",
              marginBottom: "0.5em",
            }}
          >
            {topic}
          </h2>
          <div>
            {language.labels.bites.creation.bite_n
              .replace(/{x}/, currBite + 1)
              .replace(/{n}/, biteCount)}
          </div>
          <h2
            className="heading2"
            style={{
              fontWeight: "500",
              fontSize: "1.1em",
              fontStyle: "italic",
            }}
          >
            {bitesArr[currBite]}
          </h2>
        </div>

        <div>
          {allSteps[currentStep]}
          {nextStep && allSteps[nextStep.step]}
        </div>
      </main>
    </>
  );
};

export default NewBlocks;
