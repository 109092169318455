import React, { useState, useRef } from "react";
import ImageEditor from "./ImageEditor";
import config from "../../config";
import axiosCall from "../../lib/axios";

const ChatCreator = ({ props, language, signout }) => {
  ////////////// INITIALIZE //////////////////
  const topicRef = useRef();
  const canvas_ref = useRef();

  const [topic, setTopic] = useState("");
  const [topicErr, setTopicErr] = useState("");
  const [imageErr, setImageErr] = useState("");
  const [file, setFile] = useState("");

  ////////////// PROCESS DATA /////////////////
  const submitForm = () => {
    setTopicErr("");
    setImageErr("");

    if (topic.trim() === "") {
      setTopicErr(language.labels.chat.error.title);
      topicRef.current.focus();
      return;
    }

    let canvas = canvas_ref.current;
    if (canvas && file) {
      var data_uri = canvas.toDataURL();
      newChat(topic.trim(), data_uri);
    } else {
      setImageErr(language.labels.chat.error.image);
    }
  };

  const newChat = async (topicName, avatar) => {
    let result = await axiosCall("topic/create", { topicName, avatar });
    if (result.success) {
      if (result.status === 201) {
        setTopicErr(language.labels.chat.already);
      } else {
        let _allChats = [...props.allChats];
        if (props.allChats.length > 0) {
          _allChats.unshift(result.data);
        } else {
          _allChats.push(result.data);
        }
        props.setChats(_allChats);
        props.setAllChats(_allChats);
        props.setStatus("all");
        props.setPosted("any");
        props.setTerm("");
        props.setPage("chats");
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      newChat(topicName, avatar);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// RENDER GUI //////////////////
  return (
    <div className="page-section" style={{ maxWidth: "44rem" }}>
      <h2
        className="heading2"
        style={{
          fontWeight: "bold",
          fontSize: "1.1em",
          marginTop: "1em",
        }}
      >
        {language.labels.chat.create}
      </h2>
      <input
        ref={topicRef}
        style={{ marginTop: "0.5em", width: "100%" }}
        placeholder={language.labels.chat.enter}
        aria-placeholder={language.labels.chat.enter}
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        maxLength={config.string.title}
      />
      {topicErr && (
        <div className="errtext" role="alert">
          {topicErr}
        </div>
      )}

      <h2 className="heading2" style={{ fontWeight: "500", marginTop: "1em" }}>
        {language.labels.chat.image_provide}
      </h2>
      <ImageEditor
        setFile={setFile}
        language={language}
        file={file}
        dim={400}
        ref={canvas_ref}
      />
      {imageErr && (
        <div
          style={{ fontWeight: 600, marginTop: "0.5rem" }}
          className="errtext"
          role="alert"
        >
          {imageErr}
        </div>
      )}

      <div style={{ marginTop: "1.5em", display: "flex", gap: "0.25em" }}>
        <button
          className="button-secondary"
          style={{ margin: "1em 0" }}
          onClick={() => props.setPage("chats")}
        >
          {language.labels.app.cancel}
        </button>
        <button
          className="button"
          style={{ margin: "1em 0" }}
          onClick={() => submitForm()}
        >
          {language.labels.app.action_create}
        </button>
      </div>
    </div>
  );
};

export default ChatCreator;
